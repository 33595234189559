import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import {
  getAllMailCheckboxes,
  createMailCheckbox,
  updateMailCheckbox,
  removeMailCheckbox,
} from "../../../service";

import "./index.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MailCheckboxes(props) {
  const { close } = props;

  const [checkbox, setCheckbox] = useState({ name: "", template: "" });
  const [checkboxes, setCheckboxes] = useState([]);
  const [selectedCheckboxId, setSelectedCheckboxId] = useState();
  const [isLoading, setIsLoading] =  useState(true)

  // errors
  const [nameError, setNameError] = React.useState(false);
  const [templateError, setTemplateError] = React.useState(false);

  const getTemplates = async () => {
    try {
      const res = await getAllMailCheckboxes();
      console.log("res", res);
      setCheckboxes(res.data.checkboxes);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  // validation
  const validate = () => {
    let nameError = false;
    let templateError = false;

    if (!checkbox.name) {
      nameError = true;
    }

    if (!checkbox.template) {
      templateError = true;
    }

    if (nameError || templateError) {
      setNameError(nameError);
      setTemplateError(templateError);
      return false;
    }
    return true;
  };

  const addCheckbox = async () => {
    let isValid = validate();
    if (isValid) {
      try {
        const res = await createMailCheckbox(checkbox);
        setCheckboxes([...checkboxes, res.data.checkbox]);
        setCheckbox({ name: "", template: "" });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const updateCheckbox = async () => {
    try {
      const res = await updateMailCheckbox(selectedCheckboxId, checkbox);
      console.log("res", res);

      const updatedCheckboxes = checkboxes.map((x) => {
        if (x.id == selectedCheckboxId) {
          x.name = checkbox.name;
          x.template = checkbox.template;
        }
        return x;
      });

      setCheckboxes(updatedCheckboxes);
      setCheckbox({ name: "", template: "" });
      setSelectedCheckboxId();
    } catch (e) {
      console.log(e);
    }
  };

  const removeCheckbox = (id) => {
    try {
      const res = removeMailCheckbox(id);
      const updatedCheckboxes = checkboxes.filter((x) => x.id != id);
      setCheckboxes(updatedCheckboxes);
    } catch (e) {
      console.log(e);
    }
  };

  const onEditMode = (selectedItem) => {
    setSelectedCheckboxId(selectedItem.id);
    setCheckbox({ name: selectedItem.name, template: selectedItem.template });
  };

  const deleteErrors = () => {
    setNameError(false);
    setTemplateError(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addCheckbox();
    }
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth="sm"
        onClose={close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="manageDomains-dialog"
      >
        <DialogTitle id="max-width-dialog-title">
          <span className="dialo_head_title">Mail Checkboxes</span>
        </DialogTitle>
        <DialogContent>
          <div className="manage_domains_popup">
            <div className="add_domain">
              <TextField
                className={
                  nameError
                    ? "field -regular_case inputError"
                    : "field -regular_case"
                }
                label="Name"
                value={checkbox.name}
                variant="outlined"
                onFocus={() => setNameError(false)}
                onChange={(event) =>
                  setCheckbox({ ...checkbox, name: event.target.value })
                }
              />
            </div>

            <div className="add_domain">
              <TextField
                className={
                  templateError
                    ? "field -regular_case inputError"
                    : "field -regular_case"
                }
                label="Iteraction name"
                value={checkbox.template}
                variant="outlined"
                onKeyPress={handleKeyPress}
                onFocus={() => setTemplateError(false)}
                onChange={(event) =>
                  setCheckbox({ ...checkbox, template: event.target.value })
                }
              />

              {selectedCheckboxId ? (
                <div className="updateCancelButtons">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      setSelectedCheckboxId();
                      setCheckbox({ name: "", template: "" });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={updateCheckbox}
                  >
                    Update
                  </Button>
                </div>
              ) : (
                <div className="action" onClick={addCheckbox}>
                  +
                </div>
              )}
            </div>
            {isLoading 
            ? 
              <Box sx={{ display: 'flex'}}>
                <CircularProgress style={{ color: '#427dc3', marginTop: '20px' }}/>
              </Box>
            :
            checkboxes.length > 0 && (
              <div className="domain_list">
                <div className="head_domain">
                  <div className="label">Name</div>
                  <div className="label">Iteraction name</div>
                  <div className="label">Actions</div>
                </div>

                <div className="list">
                {checkboxes.map((x) => {
                    return (
                      <div
                        className={
                          selectedCheckboxId === x.id
                            ? "domain domain--selected"
                            : "domain"
                        }
                      >
                        <div className="name">{x.name}</div>
                        <div className="name">{x.template}</div>
                        <div className="actions name">
                          <Tooltip title="Edit">
                            <IconButton
                              className="action"
                              onClick={() => {
                                onEditMode(x);
                                deleteErrors();
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Remove">
                            <IconButton
                              className="action"
                              onClick={() => {
                                removeCheckbox(x.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="user_create_dialog_action -close" onClick={close}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}