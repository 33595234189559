import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { 
    getQuestion,
    createQuestion,
    createNewsletterScripts,
    updateNewsletterScripts
} from '../../../service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageScript(props) {
    const {
        close,
        text,
        scriptId : scrId,
        scriptCB
    } = props;

    const [question, setQuestion] = React.useState(text || '');
    const [scriptId] = React.useState(scrId || '');

    const create = () => {
        const payload = {
            text: question
        };

        createNewsletterScripts(payload).then(close).catch(err => {
            console.log(err);
        });
    }

    const update = () => {
        const payload = {
            text: question
        };

        updateNewsletterScripts(scriptId, payload).then(close).catch(err => {
            console.log(err);
        });
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="manageDomains-dialog"
        >
            <DialogTitle id="max-width-dialog-title">
                { !scriptId && <span className='dialo_head_title'>Create a new</span> }
                { scriptId && <span className='dialo_head_title'>Update</span> }
            </DialogTitle>
            <DialogContent>
                <div className='manage_domains_popup'>
                    <ReactQuill style={{height: '240px', width: '100%'}}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme={"snow"}
                        onChange={(text) => setQuestion(text)}
                        defaultValue={question}
                    />
                </div>
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -generate' onClick={close}>
                Close
            </Button>
            { !scriptId && <Button className='user_create_dialog_action -close' onClick={create}>
                Create
            </Button>}
            { scriptId && <Button className='user_create_dialog_action -close' onClick={update}>
                Update
            </Button>}
            </DialogActions>
        </Dialog>
        </div>
    );
}

const Editor = {
    modules: {
        toolbar: [
          [{'header': []}, {'color': []}], // , {'color': []}, {'background': []}
          ['bold', 'italic', 'underline', 'blockquote'],
          [{'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link'],
          ['clean']
        ],
      },
    
      formats: [
        'header',
        'color',
        // 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ],
}