export default {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        width: '100vw',
        overflow: 'auto',
        paddingBottom: '20px'
    }
}