import {
    LOADING_USERS,
    ERROR_LOAD_USERS,
    SAVE_USERS,
    SAVE_SEARCH_WORD_USERS,
    SET_ORDER_USERS,
    SET_ORDER_BY_USERS,
    SET_PAGE_USERS,
    SET_ROWS_PAGE_USERS 
} from '../actions/users';

const defaultState = {
    users: [], 
    count: 0,
    searchWord: '',
    loadingRecords: false,
    errorMessage: '',
    errorLoading: false,
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'updatedAt',
}

export default (state = defaultState, action) => {
    const {
        type,
        users,
        count,
        searchWord,
        loadingRecords,
        errorMessage,
        errorLoading,
        page,
        rowsPerPage,
        order,
        orderBy
    } = action;

    switch (type) {
        case LOADING_USERS:
            return {
                ...state,
                loadingRecords
            }

        case ERROR_LOAD_USERS:
            return {
                ...state,
                loadingRecords,
                errorLoading,
                errorMessage
            }

        case SAVE_USERS:
            return {
                ...state,
                loadingRecords,
                users,
                count: count ? count : state.count
            }
        case SAVE_SEARCH_WORD_USERS:
            return {
                ...state,
                searchWord
            }

            case SET_ORDER_USERS:
            return {
                ...state,
                order
            }

        case SET_ORDER_BY_USERS:
            return {
                ...state,
                orderBy
            }

        case SET_PAGE_USERS:
            return {
                ...state,
                page
            }

        case SET_ROWS_PAGE_USERS:
            return {
                ...state,
                rowsPerPage
            }

        default:
            return state;
    }
}