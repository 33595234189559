import {
   SET_LOADER
} from '../actions';

export default ( state = {
   showLoader: false,
}, action) => {

   const {
      loaderState
   } = action;

   switch (action.type) {
      case SET_LOADER:
         {
         return {
            ...state,
            showLoader: loaderState
         }
      }

      default:
            return state;
   }
}