import {
   OPEN_NOTIFICATION,
   CLOSE_NOTIFICATION
} from '../actions';

export default ( state = {
   showNotification: false,
   notificationText: '',
   notificationType: ''
}, action) => {

   const {
      notificationText,
      notificationType
   } = action;

   switch (action.type) {
      case OPEN_NOTIFICATION:
         {
         return {
            ...state,
            showNotification: true,
            notificationText: notificationText,
            notificationType: notificationType,
         }
      }

      case CLOSE_NOTIFICATION: {
         return {
            ...state,
            showNotification: false
         }

      }

      default:
            return state;
   }
}