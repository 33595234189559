import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router-dom';

import { 
    getCustomFieldsById,
    createCustomField,
    updateCustomField
} from '../../../../service';

import {
    saveCustoms
} from '../../../../actions/customs';

import './index.scss';

function NewCustomField(props) {
    const dispatch = useDispatch();

    const [name, setName] = React.useState('');
    const [rule, setRule] = React.useState('');
    const [redirect, setRedirect] = React.useState(false);

    const {customs} = useSelector(state => state.customs);

    const saveFields = (customs) => {
        dispatch(saveCustoms(customs))
    }

    const { id } = props.match.params;

    useEffect(() => {
        const fetchData = async () => {
            getCustomFieldsById(id).then(resp => {
                const {
                    ok,
                    field
                } = resp.data;

                if(!ok) 
                    return false;

                const {
                    name,
                    rule
                } = field;

                setName(name);
                setRule(rule);
            })
        };
        
        if(id)
            fetchData();
    }, []);

    const createCustom = () => {
        const payload = {
            name,
            rule
        };

        createCustomField(payload).then(resp => {
            const {
                ok,
                field
            } = resp.data;

            if(!ok)
                return false;

            const {
                name,
                rule
            } = field;

            setName(name);
            setRule(rule);

            customs.unshift(field);
                
            saveFields(customs);
            setRedirect(true);
        }).catch(console.log);
    }

    const updateCustom = () => {
        const payload = {
            name,
            rule
        };

        updateCustomField(id, payload).then(resp => {
            const {
                ok,
                field
            } = resp.data;

            if(!ok)
                return false;

            const updatedCustoms = customs.map(custom => {
                if(id == custom.id)
                    return field
                else
                    return custom
            })
                
            saveFields(updatedCustoms);
            setRedirect(true);
        }).catch(console.log);
    }

    if(redirect)
        return <Redirect path='/'/>

    return (
        <div className='custom_field_create_page'>
            <div className='fields_section'>
                <div className='section'>
                    <div className='head_text'>Custom Field Properties</div>
                    <div className='fields'>
                        <TextField 
                            className='field -regular_case' 
                            onChange={event => setName(event.target.value)} 
                            required label="Name"
                            value={name} />
                    </div>

                    <div className='fields -column'>
                        <TextField
                            className='field -regular_case'
                            id="outlined-multiline-static"
                            label="Rule with conditions"
                            multiline
                            rows={6}
                            value={rule}
                            onChange={event => setRule(event.target.value)}
                            variant="outlined"/>

                        {/* <Button className='action' onClick={() => setPreview(true)}>
                            Preview
                        </Button> */}
                    </div>
                </div>
            </div>
            <div className='actions'>
                {!id 
                    ? 
                    <Button onClick={createCustom} className='action'>
                        Add
                    </Button>
                    :
                    <Button onClick={updateCustom} className='action'>
                        Update
                    </Button>
                }
            </div>
        </div>
    )
}

export default NewCustomField;