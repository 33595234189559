import {
    FIELDS
} from '../actions';

const { 
    SAVE_FIELDS
} = FIELDS;

const defaultState = {
    managedFields: [],
};

export default (state = {...defaultState}, action) => {
    const {
        type,
        managedFields
    } = action;

    switch (type) {
        
        case SAVE_FIELDS: {
            return {
                ...state,
                managedFields,
            }

        }

        default:
            return state;
    }
}