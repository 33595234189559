import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import renderHTML from 'react-render-html';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewScript(props) {
    const {
        close,
        textMessage,
        textMessageSecond
    } = props;

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="preview-dialog"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Preview</span>
            </DialogTitle>
            <DialogContent>
                {!textMessageSecond && renderHTML(textMessage.replace(/\n/gmi, '<br/>')) }
                {textMessageSecond && <div className='preview_dialog'>
                    <div className='dialog_title'>
                        Script Energia
                    </div>

                    <div className='dialog_content'>
                        { renderHTML(textMessage.replace(/\n/gmi, '<br/>')) }
                    </div>
                    <hr/>

                    <div className='dialog_title'>
                        Script Gas
                    </div>

                    <div className='dialog_content'>
                        { renderHTML(textMessageSecond.replace(/\n/gmi, '<br/>')) }
                    </div>
                </div> }
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -close' onClick={close}>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}