import {
   OPEN_NOTIFICATION,
   CLOSE_NOTIFICATION
} from './index';


export const openNotification = (payload) => {
   const { notificationText, notificationType } = payload;
   return {
       type: OPEN_NOTIFICATION,
       notificationText,
       notificationType
   }
}

export const closeNotification = () => {
   return {
       type: CLOSE_NOTIFICATION
   }
}