import {
    LOADING_TEMPLATES,
    ERROR_LOAD_TEMPLATES,
    SAVE_TEMPLATES,
    SAVE_SEARCH_WORD_TEMPLATES,
    SET_ORDER_TEMPLATES,
    SET_ORDER_BY_TEMPLATES,
    SET_PAGE_TEMPLATES,
    SET_ROWS_PAGE_TEMPLATES
} from '../actions/templates';

const defaultState = {
    templates: [], 
    count: 0,
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'updatedAt',
    searchWord: '',
    loadingRecords: false,
    errorMessage: '',
    errorLoading: false
}

export default (state = defaultState, action) => {
    const {
        type,
        templates,
        count,
        searchWord,
        page,
        rowsPerPage,
        order,
        orderBy,
        loadingRecords,
        errorMessage,
        errorLoading
    } = action;

    switch (type) {
        case LOADING_TEMPLATES:
            return {
                ...state,
                loadingRecords
            }

        case ERROR_LOAD_TEMPLATES:
            return {
                ...state,
                loadingRecords,
                errorLoading,
                errorMessage
            }

        case SAVE_TEMPLATES:
            return {
                ...state,
                loadingRecords,
                templates,
                count: count ? count : state.count
            }
        case SAVE_SEARCH_WORD_TEMPLATES:
            return {
                ...state,
                searchWord
            }
        case SET_ORDER_TEMPLATES:
            return {
                ...state,
                order
            }

        case SET_ORDER_BY_TEMPLATES:
            return {
                ...state,
                orderBy
            }

        case SET_PAGE_TEMPLATES:
            return {
                ...state,
                page
            }

        case SET_ROWS_PAGE_TEMPLATES:
            return {
                ...state,
                rowsPerPage
            }

        default:
            return state;
    }
}