import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import './index.scss';

function PackageCard(props) {
    const {
        package_name,
        department_code,
        department,
        client_type,
        product_type,
        vendor,
        product_name,
        products
    } = props.data;

    const {
        update,
        remove
    } = props;

    return (
        <div className='package_card'>
            <div className='header'>
                {package_name}
                <div className='help_text'>Package Name</div>
            </div>
            <div className='info'>
                <div className='details -special'>
                    <div className='detail'>
                        <div className='name'>{department_code}</div>
                        <div className='help_text'>Department Code</div>
                    </div>
                    <div className='detail'>
                        <div className='name'>{department}</div>
                        <div className='help_text'>Department</div>
                    </div>
                </div>
                <div className='details -special'>
                    <div className='detail'>
                        <div className='name'>{client_type}</div>
                        <div className='help_text'>Client Type</div>
                    </div>
                    <div className='detail'>
                        <div className='name'>{product_type}</div>
                        <div className='help_text'>Product Type</div>
                    </div>
                </div>
                <div className='details'>
                    <div className='detail'>
                        <div className='name'>{vendor}</div>
                        <div className='help_text'>Vendor</div>
                    </div>
                    <div className='detail'>
                        <div className='name'>{products.length}</div>
                        <div className='help_text'>Products</div>
                    </div>
                </div>
                <div className='actions'>
                    <Button className='action' onClick={() => update(props.data)}>Update</Button>
                    <Button className='action -remove' onClick={remove}>Remove</Button>
                </div>
            </div>
            
        </div>
    )
}


export default PackageCard