import React, { Component } from 'react';
import { Link, Route, HashRouter, Switch } from 'react-router-dom';

import RecordsPage from './list';
import CreateRecord from './new';

function Records() {
    return (
        <HashRouter basename='/records'>
            <Switch>
                <Route path='/new' component={CreateRecord}/>
                <Route path='/:id/:status' component={CreateRecord}/>
                <Route path='/:id' component={CreateRecord}/>
                <Route path='/' component={RecordsPage}/>
            </Switch>
        </HashRouter>
    )
}

export default Records