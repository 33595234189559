import {
    LOADING_CUSTOM,
    SAVE_CUSTOM
} from './index';

export const loading = (status) => {
    return {
        type: LOADING_CUSTOM,
        loadingCustoms: status
    }
}

export const saveCustoms = (customs) => {
    return {
        type: SAVE_CUSTOM,
        customs
    }
}