import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageOptions(props) {
    const {
        close,
        createAction,
        updateAction,
        data = null
    } = props;

    const properts = data ? data.properties : [];

    const [name, setName] = React.useState('');
    const [edit, setEdit] = React.useState(null);
    const [properties, setProperties] = React.useState(properts);

    const submit = () => {
        const payload = { properties, code: data.code };

        if(!data.id) {
            close();
            return createAction(payload);
        }

        updateAction(data.id, payload);
        close();
    }

    const update = (payload) => {
        const {
            name,
            index
        } = payload;

        setEdit({ name, index });
        setName(name);
    }

    const save = () => {
        if(edit) {
            setProperties( properties.map((prop, index) => (index === edit.index) ? name : prop));
        } else {
            setProperties([ ...properties, name]);
        }

        setEdit(null);     
    }

    const removeItem = (itemIndex) => {
        setProperties( properties.filter((prop, index) => index !== itemIndex));
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="edit-dialog"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Edit</span>
            </DialogTitle>
            <DialogContent className='no_padding'>
                <div className='manage_field'>
                    <TextField
                        // className='field'
                        className="field -regular_case"
                        label='Enter name'
                        value={name}
                        variant="outlined" 
                        onKeyDown={(event) => {
                            if(event.keyCode !== 13)
                                return

                            save()
                            setName('');

                            console.log(properties, 'properties')
                        }}
                        onChange={(event) => setName(event.target.value)}
                    />

                <Tooltip title='Add' placement="top">
                    <div 
                        className={ (name.length) ? 'action' : 'action -disabled'}
                        onClick={() => {
                            save();
                            setName('');
                        }}
                    >+</div>
                </Tooltip>
                </div>
                <div className='items_manage'>
                    <div className='item -default'>
                        <div className='name'>Name</div>
                        <div className='actions'>
                            Actions
                        </div>
                    </div>
                    {properties.map((prop, index) => {
                        return <div key={index} className='item'>
                            <div className='name'>{prop}</div>
                            <div className='actions'>
                                <Tooltip title='Edit' placement="top">
                                    <div className='action -edit' onClick={() => update({ name: prop, index })}></div>
                                </Tooltip>
                                <Tooltip title='Remove' placement="top">
                                    <div className='action -remove' onClick={() => {
                                        removeItem(index);
                                    }}></div>
                                </Tooltip>
                            </div>
                        </div>
                    })}
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button className='close_manage_options -close' onClick={close}>
                    Close
                </Button>
                <Button className='close_manage_options -close' onClick={submit}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}