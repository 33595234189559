import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import './index.scss';

function ListCustomField(props) {
    const customs = useSelector(state => state.customs);

    return (
        <div className='custom_field_list_page'>
            <div className='header'>
                <div className='text'>Custom Field Properties</div>
                <div className='actions'>
                    <Link to='/new'><Button className='action'>Create New</Button></Link>
                </div>
            </div>
            <div className='list_fields'>
                {customs.customs.map(custom => <Link to={'/'+ custom.id}><div className='field'>{custom.name}</div></Link>)}
            </div>
        </div>
    )
}

export default ListCustomField;