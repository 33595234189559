import React, { Component, useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { TextField, Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {useGoogleLogin} from "@react-oauth/google";

import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';

import * as service from '../../service';
import { login } from '../../actions/auth';

import style from './style';

import './login.scss';

const mapStateToProps = state => ({
    ...state,
    auth: state.auth,
    role: state.role
})

const mapDispatchToProps = dispatch => ({
    logined: (auth, role) => {
        const payload = {
            auth,
            role
        };

        return dispatch(login(payload));
    }
})

const LoginPage = (props) => {
    const [state, setState] = useState({
        login: null,
        password: null,
        auth: null,
        sectionName: 'loginSection',
        loading: false,
        qrCode: null,
        isShowBackBtn: true,
        isCodeVerificationSuccess: false,
        isCodeVerificationFailed: false
    });

    useEffect(() => {
        if (!localStorage.getItem('token')) return;

        service.checkAuth()
            .then(resp => {
                const { ok } = resp.data;
                if (ok) return;
            })
            .catch(console.log);
    }, []);

    const changeText = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const loginGoogle = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async (codeResponse) => {
          // console.log("codeResponse", codeResponse);
          responseGoogle(codeResponse.code);
        },
        onError: (errorResponse) => console.log(errorResponse),
      });

    const login = (event) => {
        event.preventDefault();

        const loginButton = document.getElementById('login-button');
        loginButton.classList.remove('amin-login-fail');

        const { login, password } = state;

        service.login({ login, password }).then(result => {
            console.log('result', result)

            if(!result.data.activated_g) {
                qrRequest();
                changeText('sectionName', 'qrSection' );
                changeText('loading', true);
            } else {
                changeText('sectionName', 'verificationSection');
                changeText('isShowBackBtn', false );
            }

            const { ok, role, token } = result.data;

            if(ok)
                localStorage.setItem('token', token);

            this.props.logined(ok, role);
        }).catch(err => {
            loginButton.classList.add('amin-login-fail');

            console.log(err);
        })
    };

    const qrRequest = () => {
        service.getQrCode().then(result => {
            setState(prevState => ({ ...prevState, qrCode:  result.data.html, loading: false }));
        }).catch(err => {
            console.log(err);
        })
    };

    const onAuthCodeChange = (authCode) => {
        changeText('authCode', authCode);

        if (authCode.length === 6) {

            service.loadAuthCode({pin: authCode}).then(result => {
                console.log(result.data.status)

                if(result.data.status) {
                    setState(prevState => ({ ...prevState, isCodeVerificationSuccess: true }));
                } else {
                    setState(prevState => ({ ...prevState, isCodeVerificationSuccess: false }));

                }

            }).catch(err => {
                console.log(err);
            })
        } else {
            setState(prevState => ({ ...prevState, isCodeVerificationSuccess: false, isCodeVerificationFailed: false }));
        }
    };

    const responseGoogle = (response) => {
        console.log(response, 'response')
        let errorElement = document.getElementById("googleVerificationErrorText");

        service.loginVerifyEmail({ token: response }).then(result => {
            if(result.data.ok) {
                changeText('sectionName', 'verificationSuccessSection');

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                errorElement.textContent += "Verification fail";
            }
        }).catch(err => {
            console.log(err)
        })
    };

    const auth = state.auth;

    if (auth) window.location.reload();

    return (
        <div className='login_page'>
                <img className='login_logo' src='/assets/images/logo/selectra_logoWhite.svg' />
                {state.sectionName === 'loginSection' && 
                <form style={ style.form } onSubmit={login}>
                    <div className='login_fields' style={ style.form }>
                        <TextField required label="Login" type='text' style={ style.loginFl } onChange={ value => changeText('login', value.target.value) }/>
                        <TextField required label="Password" type="password" style={style.loginFl} onChange={value => changeText('password', value.target.value)} />
                    </div>
                    <Button id='login-button' style={ style.button } variant="contained" size="medium" type='submit'>Login</Button>
                </form>
    }
    
                {state.sectionName === 'qrSection' &&

                    <div className="qrSection">
                        <div className="qrSection__accentText">Scan the QR code in the Google Authenticator app</div>

                        {state.loading && <div className="qrSection__loading">Loading ...</div>}
                        <div className="qrSection__qrWrapper" dangerouslySetInnerHTML={{ __html: state.qrCode }} />

                        <Button style={style.button} onClick={() => changeText('sectionName', 'verificationSection')} variant="contained" size="medium">Next</Button>

                    </div>
                }

                {state.sectionName === 'verificationSection' &&
                    <div className="verificationSection">
                        {state.isShowBackBtn && <div onClick={() => changeText('sectionName', 'qrSection' )} className="verificationSection__backBtn"><KeyboardBackspaceIcon size="small" />back</div>}
                        <div className="loginPage__text">Enter code here</div>
                        <OtpInput
                            value={state.authCode}
                            onChange={onAuthCodeChange}
                            numInputs={6}
                            inputStyle="verificationSection__code"
                            focusStyle="verificationSection__code--focus"
                            isInputNum="true"
                            shouldAutoFocus="true"
                        />


                        {state.isCodeVerificationSuccess &&
                            <div className="verificationSection__response">
                                <div className="verificationSection__successIconWrapper">
                                    <object type="image/svg+xml" className="verificationSection__successIcon" data={'/assets/images/icons/verificationSuccess.svg'}></object>
                                </div>
                                <div className="loginPage__text--accent">Succesfully!</div>
                            </div>
                        }

                        {state.isCodeVerificationFailed &&
                            <div className="verificationSection__response">
                                <div className="verificationSection__successIconWrapper">
                                    <img src='/assets/images/icons/verificationFailed.svg' alt='code is not valid' />
                                </div>
                                <div className="loginPage__text--error">Code is not vaid!</div>
                            </div>
                        }

                        <div className={state.isCodeVerificationSuccess ? "verificationSection__btn" : "verificationSection__btn--disabled"}>
                            <Button style={style.button} onClick={() => changeText('sectionName', 'googleVerification' )} variant="contained" size="medium">Next</Button>
                        </div>
                    </div>
                }

                {state.sectionName === 'googleVerification' &&
                    <div className="googleVerification">
                        <button className="menu__googleBtn" onClick={() => loginGoogle()}>
                            <img src="./assets/images/icons/google.svg" />
                            Verify email
                        </button>
                        <div id="googleVerificationErrorText" className="loginPage__text--error"></div>
                    </div>
                }

                {state.sectionName === 'verificationSuccessSection' &&

                    <div className="verificationSuccess">
                        <div className="verificationSuccess__text">Verification was successful!</div>
                        <div className="verificationSuccess__successIconWrapper">
                            <object type="image/svg+xml" className="verificationSuccess__successIcon" data={'/assets/images/icons/verificationSuccess-white.svg'}></object>
                        </div>
                    </div>
                }

            </div>
    );
};

/*
class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: null,
            password: null,
            auth: null,
            sectionName: 'loginSection',
            loading: false,
            qrCode: null,
            isShowBackBtn: true,
            isCodeVerificationSuccess: false,
            isCodeVerificationFailed: false
        }

        this.login = this.login.bind(this);
        this.changeText = this.changeText.bind(this);
    }

    changeText(name, value) {
        const data = {};
        data[name] = value.target.value;

        this.setState(data);
    }

    componentDidMount() {

        if(!localStorage.getItem('token'))
            return;

        service.checkAuth().then(resp => {
            const { ok } = resp.data;

            if(ok)
                return 
        }).catch(console.log);
    }

    login(event) {
        event.preventDefault();

        const loginButton = document.getElementById('login-button');
        loginButton.classList.remove('amin-login-fail');

        const { login, password } = this.state;

        service.login({ login, password }).then(result => {
            console.log('result', result)

            if(!result.data.activated_g) {
                this.qrRequest();
                this.setState( { sectionName: 'qrSection' });
                this.setState( { loading: true });
            } else {
                this.setState( { sectionName: 'verificationSection' });
                this.setState( { isShowBackBtn: false });
            }

            const { ok, role, token } = result.data;

            if(ok)
                localStorage.setItem('token', token);
                
            // this.setState({auth: ok});

            this.props.logined(ok, role);
        }).catch(err => {
            loginButton.classList.add('amin-login-fail');

            console.log(err);
        })
    }

    qrRequest() {
        service.getQrCode().then(result => {
            this.setState( { qrCode: result.data.html });
            this.setState( { loading: false });
        }).catch(err => {
            console.log(err);
        })
    }
    


    onAuthCodeChange = (authCode) => {
        this.setState({ authCode });

        if (authCode.length === 6) {

            service.loadAuthCode({pin: authCode}).then(result => {
                console.log(result.data.status)

                if(result.data.status) {
                    this.setState( { isCodeVerificationSuccess: true });
                } else {
                    this.setState( { isCodeVerificationFailed: true });

                }

            }).catch(err => {
                console.log(err);
            })
        } else {
            this.setState( { isCodeVerificationSuccess: false });
            this.setState( { isCodeVerificationFailed: false });
        }
    }

    responseGoogle = (response) => {
        let errorElement = document.getElementById("googleVerificationErrorText");

        service.loginVerifyEmail({ token: response.tokenId }).then(result => {
            if(result.data.ok) {
                this.setState( { sectionName: 'verificationSuccessSection' });

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                errorElement.textContent += "Verification fail";
            }
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        const auth = this.state.auth;
        
        if(auth)
            window.location.reload();

        return (
            <div className='login_page'>
                <img className='login_logo' src='/assets/images/logo/selectra_logoWhite.svg' />
                {this.state.sectionName === 'loginSection' && 
                <form style={ style.form } onSubmit={this.login}>
                    <div className='login_fields' style={ style.form }>
                        <TextField required label="Login" type='text' style={ style.loginFl } onChange={ value => this.changeText('login', value) }/>
                        <TextField required label="Password" type="password" style={style.loginFl} onChange={value => this.changeText('password', value)} />
                    </div>
                    <Button id='login-button' style={ style.button } variant="contained" size="medium" type='submit'>Login</Button>
                </form>
    }
    
                {this.state.sectionName === 'qrSection' &&

                    <div className="qrSection">
                        <div className="qrSection__accentText">Scan the QR code in the Google Authenticator app</div>

                        {this.state.loading && <div className="qrSection__loading">Loading ...</div>}
                        <div className="qrSection__qrWrapper" dangerouslySetInnerHTML={{ __html: this.state.qrCode }} />

                        <Button style={style.button} onClick={() => this.setState({ sectionName: 'verificationSection' })} variant="contained" size="medium">Next</Button>

                    </div>
                }

                {this.state.sectionName === 'verificationSection' &&
                    <div className="verificationSection">
                        {this.state.isShowBackBtn && <div onClick={() => this.setState({ sectionName: 'qrSection' })} className="verificationSection__backBtn"><KeyboardBackspaceIcon size="small" />back</div>}
                        <div className="loginPage__text">Enter code here</div>
                        <OtpInput
                            value={this.state.authCode}
                            onChange={this.onAuthCodeChange}
                            numInputs={6}
                            inputStyle="verificationSection__code"
                            focusStyle="verificationSection__code--focus"
                            isInputNum="true"
                            shouldAutoFocus="true"
                        />


                        {this.state.isCodeVerificationSuccess &&
                            <div className="verificationSection__response">
                                <div className="verificationSection__successIconWrapper">
                                    <object type="image/svg+xml" className="verificationSection__successIcon" data={'/assets/images/icons/verificationSuccess.svg'}></object>
                                </div>
                                <div className="loginPage__text--accent">Succesfully!</div>
                            </div>
                        }

                        {this.state.isCodeVerificationFailed &&
                            <div className="verificationSection__response">
                                <div className="verificationSection__successIconWrapper">
                                    <img src='/assets/images/icons/verificationFailed.svg' alt='code is not valid' />
                                </div>
                                <div className="loginPage__text--error">Code is not vaid!</div>
                            </div>
                        }

                        <div className={this.state.isCodeVerificationSuccess ? "verificationSection__btn" : "verificationSection__btn--disabled"}>
                            <Button style={style.button} onClick={() => this.setState({ sectionName: 'googleVerification' })} variant="contained" size="medium">Next</Button>
                        </div>
                    </div>
                }

                {this.state.sectionName === 'googleVerification' &&
                    <div className="googleVerification">
                        <div className="loginPage__text">Verify youre E-mail!</div>
                        <div className="googleVerification__verifyBtn">
                            <GoogleLogin
                                clientId="189062098162-ihhmce8opmo2rkdfdm6kkiplbe9cuc3f.apps.googleusercontent.com"
                                buttonText="Verify with Google"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                        <div id="googleVerificationErrorText" className="loginPage__text--error"></div>
                    </div>
                }

                {this.state.sectionName === 'verificationSuccessSection' &&

                    <div className="verificationSuccess">
                        <div className="verificationSuccess__text">Verification was successful!</div>
                        <div className="verificationSuccess__successIconWrapper">
                            <object type="image/svg+xml" className="verificationSuccess__successIcon" data={'/assets/images/icons/verificationSuccess-white.svg'}></object>
                        </div>
                    </div>
                }

            </div>
        );
    }
}
*/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);