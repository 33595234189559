import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './index.scss';

import { pdfsLocation } from '../../service';

const headCells = [
  { id: 'name', disableSort: true, numeric: false, disablePadding: false, label: 'Name' },
  { id: 'id', disableSort: true, numeric: true, disablePadding: false, label: 'ID User' },
  { id: 'role', disableSort: true, numeric: true, disablePadding: false, label: 'Role' },
  { id: 'login', disableSort: true, disablePadding: false, label: 'Login' },
//   { id: 'team', disableSort: true, disablePadding: false, label: 'Team' },
  { id: 'updatedAt', disableSort: false, disablePadding: false, label: 'Last Modified' },
  { id: 'actions', disableSort: true, disableSort: true, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;


  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className='head_cell'
          >
              { !headCell.disableSort
                ?
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                    >
                        {headCell.label}
                    </TableSortLabel>
                :
                    headCell.label
              }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function UsersTable(props) {
    const {
      users,
      count,
      order,
      orderBy,
      page,
      rowsPerPage,
      searchWord
    } = props.data;

    const {
      handleRequestSort,
      handleChangePage,
      handleChangeRowsPerPage,
      setPage,
      setRowsPerPage,
      askRemove,
      updateUser,
      showLogs,
      resetGoogleAuth
    } = props.actions;

    return (
      <div className='table_users'>
        <Paper className='table'>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size='medium'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={count}
              />
              <TableBody>
                {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >

                        <TableCell className='body_cell -last_name' padding='default' scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell className='body_cell -last_name' padding='default' scope="row">
                          {row.id}
                        </TableCell>

                        <TableCell className='body_cell -last_name' padding='default' scope="row">
                          {row.role}
                        </TableCell>

                        <TableCell className='body_cell -last_name' padding='default' scope="row">
                          {row.login}
                        </TableCell>

                        {/* <TableCell className='body_cell -last_name' padding='default' scope="row">
                          team
                        </TableCell> */}

                        <TableCell className='body_cell -date'>{moment(row.updatedAt).format('DD/MM/YYYY hh:mm a')}</TableCell>
                        <TableCell padding='none' className='table_actions'>
                          {renderActions(row, askRemove, updateUser, showLogs, resetGoogleAuth)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }

export default UsersTable

const renderActions = (row, askRemove, updateUser, showLogs, resetGoogleAuth) => {
  return (
    <div className='actions usersTable__actions'>
      <Tooltip title='Edit' placement="top">
        <div className='action -edit' onClick={() => updateUser(row)}></div>
      </Tooltip>
      <Tooltip title='Reset Google Auth' placement="top">
        <div className='action -update' onClick={() => resetGoogleAuth(row)}></div>
      </Tooltip>
      <Tooltip title='Remove' placement="top">
        <div className='action -remove' onClick={() => askRemove(row)}></div>
      </Tooltip>
      <Tooltip title='Logs' placement="top">
        <div className='action -logs' onClick={() => showLogs(row)}></div>
      </Tooltip>
    </div>
  )
}