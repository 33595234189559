import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import SearchBar from '../../components/userSearch';
import UserCard from '../../components/userCard';
import UserDialog from '../../components/dialogs/createUser';
import RemoveDialog from '../../components/dialogs/remove';
import UserLogsDialog from '../../components/dialogs/showUserLogs';
import ResetGoogleAuthDialog from '../../components/dialogs/resetGoogleAuth';


import { saveUsers, saveSearchWord } from '../../actions/users';

import { 
    openNotification
} from '../../actions/notification';

import * as service from '../../service';

import './index.scss';
import UsersTable from '../../components/usersTable';

import { 
    loadingError,
    saveSearchWrod,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage
} from '../../actions/users';

import './index.scss';
import { HelpOutline } from '@material-ui/icons';

const mapStateToProps = state => ({
    ...state.users
})

const mapDispatchToProps = dispatch => ({
    saveUsers(payload) {
        return dispatch(saveUsers(payload));
    },

    saveSearchWord: (word) => {
        return dispatch(saveSearchWord(word));
    },
    setOrder: (order) => {
        return dispatch(setOrder(order));
    },
    setOrderBy: (orderBy) => {
        return dispatch(setOrderBy(orderBy));
    },
    setPage: (page) => {
        return dispatch(setPage(page));
    },
    setRowsPerPage: (rowsPerPage) => {
        return dispatch(setRowsPerPage(rowsPerPage));
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
})


function UsersPage(props) {

   



    const [openUserDialog, setOpenUserDialog] = React.useState(false);
    const [openRemove, setOpenRemove] = React.useState(false);
    const [openLogsDialog, setOpenLogsDialog] = React.useState(false);
    const [openResetAuthDialog, setOpenResetAuthDialog] = React.useState(false);
    const [userToResetGoogleAuth, setUserToResetGoogleAuth] = React.useState(null);
    const [resetGoogleAuthSuccess, setResetGoogleAuthSuccess] = React.useState(false)
    const [removeUser, setRemoveUser] = React.useState(null);
    const [userToUpdate, setUserUpdate] = React.useState(null);
    const [perPage, setPerPage] = React.useState(10);

    const [selectedLevel, setSelectedLevel] = React.useState(null);

    const defaultUsers = props.users;
    const [filteredUsers, setFilteredUsers] = React.useState([]);

    const [userLevels, setUserLevels] = React.useState([])  

    const filterUsers = () => {
        if(!selectedLevel) {
            setFilteredUsers([])
            return
        };

        if(selectedLevel === 'user' || selectedLevel === 'admin') {
            let updatedUsers = defaultUsers.filter(x => x.role === selectedLevel)
            setFilteredUsers(updatedUsers)
            return
        }

        if(selectedLevel === 'usersWithFE') {
            let updatedUsers = defaultUsers.filter(x => x.send_email)
            setFilteredUsers(updatedUsers)
            return
        }

        let updatedUsers = defaultUsers.filter(x => x.level === selectedLevel)
        setFilteredUsers(updatedUsers)
        console.log('updatedUsers', updatedUsers)
    }

    useEffect(() => {
        filterUsers()
    }, [selectedLevel])
    
    useEffect(()=> {
        if(props.users.length < 1) return ;
        setUserLevels([
            {id: 'admin', title: 'Admin', number: props.users.filter(x => x.role === 'admin').length},
            {id: 'user', title: 'Users', number: props.users.filter(x => x.role === 'user').length},
            {id: 'usersWithFE', title: 'Users with FE', number: props.users.filter(x => x.send_email).length},
            {id: 'base', title: 'Base', number: props.users.filter(x => x.level === 'base').length},
            {id: 'junior1', title: 'Junior1', number: props.users.filter(x => x.level === 'junior1').length},
            {id: 'junior2', title: 'Junior2', number: props.users.filter(x => x.level === 'junior2').length},
            {id: 'intermedio', title: 'Intermedio', number: props.users.filter(x => x.level === 'intermedio').length},
            {id: 'esperto', title: 'Esperto', number: props.users.filter(x => x.level === 'esperto').length},
            {id: 'superAgent', title: 'SuperAgent', number: props.users.filter(x => x.level === 'superAgent').length}, 
        ])

    }, [props.users])

    const setSearchWord = (searchWord) => {
        props.saveSearchWord(searchWord);
    }
    
    const handleCreateUser = (payload) => {
        setOpenUserDialog(false);

        service.createUser(payload).then(resp => {
            const {
                ok,
                user
            } = resp.data;

            if(!ok)
                return;

            users.push(user);

            props.saveUsers({
                users,
                count: users.length
            })
        });
    }

    const handleUpdateUser = (id, payload) => {
        setOpenUserDialog(false);

        service.updateUser(id, payload).then(resp => {
            const {
                ok,
            } = resp.data;

            if(!ok)
                return;

            const updatedUsers = users.map(user => {
                if(user.id !== id)
                    return user;

                return {
                    id,
                    ...payload
                };
            })

            props.saveUsers({
                users: updatedUsers,
                count: users.length
            })
        });
    }

    const updateUser = (user) => {
        setUserUpdate(user);
        setOpenUserDialog(true);
    }

    const showLogs = (user) => {
        setUserUpdate(user);
        setOpenLogsDialog(true);
    }

    const handleCloseLogs = () => {
        setOpenLogsDialog(false);
    }

    const resetGoogleAuth = (user) => {
        setOpenResetAuthDialog(true);
        setUserToResetGoogleAuth(user)
    }

    const handleCloseResetAuth = () => {
        setOpenResetAuthDialog(false);
        setResetGoogleAuthSuccess(false);
    }

    const handleResetGoogleAuth = () => {
        const userId = userToResetGoogleAuth.id;
        service.resetGoogleAuth(userId).then(resp => {
            const {
                ok
            } = resp.data;

            if (!ok)
                return false;
            setResetGoogleAuthSuccess(true);
        })
    }

    const handleRemoveUser = () => {
        const userId = removeUser.id;

        service.removeUser(userId).then(resp => {
            const {
                ok
            } = resp.data;

            if(!ok)
                return false;

            const usersLeft = users.filter(user => user.id !== userId);

            props.saveUsers({
                users: usersLeft,
                count: usersLeft.length
            })

            setOpenUserDialog(false);
            handleCancelRemoveUser()
        })
    }

    const handleCancelRemoveUser = () => {
        setOpenRemove(false);
        setRemoveUser(null);
    }

    const askRemove = (user) => {
        setOpenRemove(true);
        setRemoveUser(user);
    }

    const handleSearch = (word) => {
        props.saveSearchWord(word);

        service.searchUsers({searchWord: word }).then(resp => {
            const {
                ok,
                users,
                count
            } = resp.data;

            props.saveUsers({ users, count });

            props.setPage(0);
        });

    };

    const handleRefresh = () => {

        service.searchUsers(searchWord).then(resp => {
            const {
                ok,
                users,
                count
            } = resp.data;

            props.saveUsers({ users, count });
            props.openNotification({ notificationText: 'User table has been refreshed', notificationType: 'success' });
        });
    }


    const handleChangePage = (event, newPage) => {
        const leng = users.length;
  
        if(leng <= (rowsPerPage * page) + rowsPerPage) {
            const payload = getRequestParams({ page: newPage });
            
            service.searchUsers(payload).then(resp => {
                const {
                    ok,
                    users,
                    count
                } = resp.data;

               // const newTemplates = [...templates, ...respTemplates];
    
                props.saveUsers({ users, count });
                props.setPage(newPage);
            })
        }else {
            props.setPage(newPage);
        }
    };

    const handleRequestSort = (event, prop) => {
        const isAsc = orderBy === prop && order === 'asc';
        const sort = isAsc ? 'desc' : 'asc'
        setOrder(sort);
        setOrderBy(prop);

        const users = props.users.sort(function(a, b) {
            if(isAsc){
                return new Date(b.updatedAt) - new Date(a.updatedAt); 
            }
            else {
                return new Date(a.updatedAt) - new Date(b.updatedAt); 
            }
        });

        const count = users.length;

        props.saveUsers({ users, count });
        props.setPage(0);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getRequestParams = (addParams) => {
        const payload = {
            searchWord,
            columnName: orderBy,
            order,
            limit: rowsPerPage,
            page
        }

        return { ...payload, ...addParams };
    }


    // Data
    const {
        users,
        count,
        searchWord,
        page,
        rowsPerPage,
        order,
        orderBy
    } = props;

    const tableData = {
        page,
        users: filteredUsers.length ? filteredUsers : users,
        rowsPerPage,
        count : filteredUsers.length ? filteredUsers.length : users.length,
        order,
        orderBy
    };

    const setOrder = (order) => {
        props.setOrder(order);
    }

    const setOrderBy = (orderBy) => {
        props.setOrderBy(orderBy);
    }

    const setPage = (page) => {
        props.setPage(page);
    }

    const setRowsPerPage = (rowsPerPage) => {
        setPerPage(rowsPerPage);
        props.setRowsPerPage(rowsPerPage);
    }


    const tableActions = {
        handleRequestSort,
        setPage,
        setRowsPerPage,
        setSearchWord,
        handleChangePage,
        handleChangeRowsPerPage,
        askRemove,
        updateUser,
        showLogs,
        resetGoogleAuth
    };

    return (
        <div className='users_list_page'>
            {openUserDialog && <UserDialog 
                userToUpdate={userToUpdate} 
                createUser={handleCreateUser} 
                updateUser={handleUpdateUser} 
                open={openUserDialog} 
                close={() => setOpenUserDialog(false)}/>}
            {openRemove &&  <RemoveDialog text='the user' remove={handleRemoveUser} close={handleCancelRemoveUser}/>}
            {openLogsDialog &&  <UserLogsDialog user={userToUpdate} close={handleCloseLogs}/>}
            {openResetAuthDialog &&  <ResetGoogleAuthDialog reset={handleResetGoogleAuth} success={resetGoogleAuthSuccess} close={handleCloseResetAuth}/>}
            <div className='search'>
            <div className='head_bar'>
                <SearchBar search={handleSearch} searchWord={searchWord} setSearchWord={setSearchWord}/>
                <div className='actions'>
                    <Button className='action -refresh' onClick={handleRefresh}>Refresh</Button>
                    <Button className='action -create' onClick={event => setOpenUserDialog(true)}>Create new</Button>
                </div>
            </div>
            </div>

            {userLevels.length > 0 &&
                <div className='userLevels'>
                    {userLevels.map(x=> (
                        <div className={x.number === 0 ? 'userLevelWrapper--disabled userLevelWrapper' : selectedLevel === x.id ? 'userLevelWrapper userLevelWrapper--active' : 'userLevelWrapper'} onClick={() => setSelectedLevel(selectedLevel === x.id ? null : x.id)}>
                            <div className='userLevelTitle'>{x.title}</div>
                            <div className='userLevelNumber'>{x.number}</div>
                        </div>
                    ))}
                </div>
            }

            <UsersTable data={tableData} actions={tableActions}/>

        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersPage);