import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import TextField from '@material-ui/core/TextField';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { 
    getAllEmails,
    createEmail,
    updateEmail,
    removeEmail,
    changeOrder
} from '../../../service';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageEmails(props) {
    const {
        close,
    } = props;

    const [leads, setLeads] = React.useState([]);
    const [id, setId] = React.useState(null);
    const [leadName, setLeadName] = React.useState('');
    const [emails, setEnails] = React.useState([]);
    const [emailName, setEmailName] = React.useState('');
    const [domainsLoaded, setDomainsLoaded] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [lead, setLead] = React.useState('');
    const [configs, setConfigs] = React.useState([]);
    const [load, setLoad] = React.useState(true);

    const [integrationName, setIntegrationName] = React.useState('');

    useEffect(() => {
        getAllEmails().then(resp => {
            const { ok, configs } = resp.data;

            setConfigs(configs);
        })
    }, [load])

    const removeById = (id) => {
        removeEmail(id).then(resp => {
            const { 
                ok,
            } = resp.data;

            if(!ok)
                console.log("Can't remove");
        })

        setConfigs(configs.filter(conf => conf.id != id));
    }

    const edit = (config) => {
        const {
            leads,
            emails,
            integration_name,
            id
        } = config;

        const emls = emails.split(';');
        const lds = leads.split(';');

        setId(id);
        setEnails(emls);
        setLeads(lds);
        setIntegrationName(integration_name);

    }

    const create = () => {
        const payload = {
            leads: leads.join(';'),
            emails: emails.join(';'),
            integration_name: integrationName
        }

        createEmail(payload).then(resp => {
            const { ok, config } = resp.data;

            setConfigs([config, ...configs]);
        })

        setId(null);
        setEnails([]);
        setLeads([]);
        setLeadName('');
        setEmailName('');
        setIntegrationName('');
    }

    const update = () => {
        const payload = {
            leads: leads.join(';'),
            emails: emails.join(';'),
            integration_name: integrationName
        }

        updateEmail(id, payload).then(resp => {
            getAllEmails().then(resp => {
                const { ok, configs } = resp.data;
    
                setConfigs(configs);
            });
        })

        clear();
    }

    const clear = () => {
        setId(null);
        setEnails([]);
        setLeads([]);
        setLeadName('');
        setEmailName('');
        setIntegrationName('');
    }

    const rmoveLeadItem = (index) => {
        setLeads(leads.filter((ld, ind) => ind != index));
    }

    const rmoveEmailItem = (index) => {
        setEnails(emails.filter((ld, ind) => ind != index));
    }

    const moveConfig = (payload) => {
        changeOrder(payload).then(resp => {
            setLoad(!load);
        })
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='md'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="manageEmails-dialog"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Manage emails</span>
            </DialogTitle>
            <DialogContent>
                <div className='manage_emails_popup'>
                    <h4>Test configuration</h4>
                    <div className='test_fields'>
                        <TextField
                            className='field -regular_case'
                            label='Enter lead'
                            value={lead}
                            variant="outlined" 
                            onChange={(event) => setLead(event.target.value)}
                        />
                        <TextField
                            className='field -regular_case'
                            label='Enter email'
                            value={email}
                            variant="outlined" 
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>

                    <h4>Create new config</h4>

                    <div className='create_config'>
                        <div className="config_section">
                            <TextField
                                className='field -regular_case'
                                label='Integration Name'
                                value={integrationName}
                                variant="outlined" 
                                onChange={(event) => setIntegrationName(event.target.value)}
                            />
                        </div>
                        <div className='config_section -multiple'>
                            <div className='section_leads'>
                                <div className='leads_add'>
                                    <TextField
                                        className='field -regular_case'
                                        label='Lead config'
                                        value={leadName}
                                        variant="outlined" 
                                        onChange={(event) => setLeadName(event.target.value)}
                                    />
                                    <div className='action' onClick={() => {
                                        setLeads([...leads, leadName]);
                                        setLeadName('');
                                    }}>+</div>
                                </div>
                                <div className='items_list'>
                                    {leads.map((lead, index) => {
                                        return <div className='item' onClick={() => rmoveLeadItem(index)}>{lead}</div>
                                    })}
                                </div>
                            </div>
                            <div className='section_leads'>
                                <div className='leads_add'>
                                    <TextField
                                        className='field -regular_case'
                                        label='Email confige'
                                        value={emailName}
                                        variant="outlined" 
                                        onChange={(event) => setEmailName(event.target.value)}
                                    />
                                    <div className='action' onClick={() => {
                                        setEnails([...emails, emailName]);
                                        setEmailName('');
                                    }}>+</div>
                                </div>
                                <div className='items_list'>
                                    {emails.map((email, index) => {
                                        return <div className='item' onClick={() => rmoveEmailItem(index)}>{email}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='actions'>
                            { !id && <Button className='action -prim' onClick={create}>Create</Button> }
                            { id && <Button className='action -prim' onClick={update}>Update</Button> }
                            <Button className='action -red' onClick={clear}>Clear</Button>
                        </div>
                    </div>
                    <div className='domain_list'>
                        <div className='head_config'>
                            <div className='label -item'>Name</div>
                            <div className='label -item'>Lead</div>
                            <div className='label -item'>Email</div>
                            <div className='label -itemlast'>Actions</div>
                        </div>
                        <div className='list'>
                            {configs.map((config, index) => {
                                let validLead = false;
                                let validL = false;
                                let valid = false;
                                
                                try {
                                    validLead = config.leads ? config.leads.split(';').some(ld => new RegExp(`(${ld})`).test(lead)) : lead ? false : true;
                                    validL = config.emails ? config.emails.split(';').some(ld => new RegExp(`(${ld})`).test(email)) : email ? false : true;
                                } catch(e) {
                                    console.log(e);
                                }

                                if(!validLead || !validL) {
                                    if(config.integration_name === 'ITA Mailing Trustpilot') {
                                        valid = true;
                                    }
                                } else {
                                    valid = (validLead && validL)
                                }

                                return <div className='config' key={config.id} style={ (lead || email) ? { backgroundColor: (valid) ? '#d7ead7': '#ffd4d4'} : {}}>
                                    <div className='name -item'>{config.integration_name}</div>
                                    <div className='name -item'>{config.leads}</div>
                                    <div className='name -item'>{config.emails}</div>
                                    <div className='actions'>
                                        { index !== 0 && <Tooltip title='Up'>
                                            <IconButton className='action -prim' onClick={() => { moveConfig({ idFrom: configs[index - 1]?.id, idTo: config?.id  }) }}>
                                                <ArrowUpwardIcon/>
                                            </IconButton>
                                        </Tooltip> }

                                        { (index + 1) !== configs.length && <Tooltip title='Down'>
                                            <IconButton className='action -prim' onClick={() => { moveConfig({ idFrom: config?.id, idTo: configs[index + 1]?.id }) }}>
                                                <ArrowDownwardIcon/>
                                            </IconButton>
                                        </Tooltip> }

                                        <Tooltip title='Edit'>
                                            <IconButton className='action -prim' onClick={() => { edit(config) }}>
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Remove'>
                                            <IconButton className='action -red' onClick={() => { removeById(config.id) }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            })}
                            
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className='user_create_dialog_action -close' onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}