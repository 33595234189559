import { PACKAGES } from './index';

const {
    SAVE_PACKAGES
} = PACKAGES;

export const savePackages = (payload) => {
    const {
        packages,
        count
    } = payload;

    return {
        type: SAVE_PACKAGES,
        packages,
        count
    }
}