import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import SearchBar from '../../components/packagesSearchBar';
import PackageCard from '../../components/packageCard';
import PackageDialog from '../../components/dialogs/packageDialog';
import RemoveDialog from '../../components/dialogs/remove';

import { savePackages } from '../../actions/packages';

import { 
    openNotification
} from '../../actions/notification';

import * as service from '../../service';

import './index.scss';

const mapStateToProps = state => ({
    ...state.packages
})

const mapDispatchToProps = dispatch => ({
    savePackages(payload) {
        return dispatch(savePackages(payload));
    },

    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
})


function PackagesPage(props) {
    const [openPackagePopup, setOpenPackagePopup] = React.useState(false);
    const [openRemove, setOpenRemove] = React.useState(false);
    const [removePackage, setRemovePackage] = React.useState(null);
    const [packageToUpdate, setPackageUpdate] = React.useState(null);
    const [searchWord, setSearchWord] = React.useState('');
    const [filteredPackages, setFilteredPackages] = React.useState([]);

    const {
        packages
    } = props;
    
    const handleCreatePackage = (payload) => {
        setOpenPackagePopup(false);

        service.createPackage(payload).then(resp => {
            const {
                ok,
                package: pack
            } = resp.data;

            if(!ok)
                return;

            pack.products = pack.products ? JSON.parse(pack.products) : []

            packages.push(pack);

            props.savePackages({
                packages,
                count: packages.length
            })
        });
    }

    const handleUpdatePackage = (id, payload) => {
        setOpenPackagePopup(false);

        service.updatePackage(id, payload).then(resp => {
            const {
                ok,
            } = resp.data;

            if(!ok)
                return;

            const updatedPackages = packages.map(pack => {
                if(pack.id !== id)
                    return pack;

                return {
                    id,
                    ...payload,
                    products: payload.products ? JSON.parse(payload.products) : []
                };
            })

            props.savePackages({
                packages: updatedPackages,
                count: updatedPackages.length
            })
        });
    }

    const updatePackage = (pack) => {
        setPackageUpdate(pack);
        setOpenPackagePopup(true);
    }

    const handleRemovePackage = () => {
        const packageId = removePackage.id;

        service.removePackage(packageId).then(resp => {
            const {
                ok
            } = resp.data;

            if(!ok)
                return false;

            const packagesLeft = packages.filter(pack => pack.id !== packageId);

            props.savePackages({
                packages: packagesLeft,
                count: packagesLeft.length
            })

            setOpenPackagePopup(false);
            handleCancelRemovePopup()
        })
    }

    const handleCancelRemovePopup = () => {
        setOpenRemove(false);
        setRemovePackage(null);
    }

    const askRemove = (pack) => {
        setOpenRemove(true);
        setRemovePackage(pack);
    }

    const handleSearch = (word) => {
        setSearchWord(word)
        
        const rexExp = new RegExp(`(${word})`, 'im');

        const filteredPackages = packages.filter(pack => rexExp.test(pack.package_name));

        setFilteredPackages(filteredPackages);
    };

    const packagesToUse = searchWord ? filteredPackages : packages;

    return (
        <div className='packages_list_page'>
            {openPackagePopup && <PackageDialog 
                packageToUpdate={packageToUpdate} 
                createPackage={handleCreatePackage} 
                updatePackage={handleUpdatePackage} 
                open={openPackagePopup} 
                close={() => setOpenPackagePopup(false)}/>}

            {openRemove && <RemoveDialog text='the package?' remove={handleRemovePackage} close={handleCancelRemovePopup}/>}

            <div className='head_bar'>
                <SearchBar search={handleSearch} searchWord={searchWord} setSearchWord={setSearchWord}/>
                <div className='actions'>
                    <Button className='action -create' onClick={event => setOpenPackagePopup(true)}>Create new</Button>
                </div>
            </div>

            <div className='packages_list'>
                {packagesToUse.map((pack, index) => {
                    return (
                        <PackageCard key={pack.id} data={pack} update={updatePackage} remove={() => askRemove(pack)}/>
                    )
                })}
            </div>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PackagesPage);