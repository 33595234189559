import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Link } from 'react-router-dom';

import TemplatesTable from '../../../components/templatesTable';
import SearchBar from '../../../components/templatesTable/searchBar';
import RemoveDialog from '../../../components/dialogs/remove';
import { Redirect } from 'react-router-dom';

import { 
    loadingTemplates,
    loadingError,
    saveTemplates,
    saveSearchWrod,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage
} from '../../../actions/templates';

import { 
    openNotification
} from '../../../actions/notification';


import { debounce } from '../../../libs/ownScripts';
import * as service from '../../../service';

import './index.scss';

const mapStateToProps = state => ({
    ...state.templates
})

const mapDispatchToProps = dispatch => ({
    saveTemplates: (payload) => {
        return dispatch(saveTemplates(payload));
    },
    saveSearchWord: (word) => {
        return dispatch(saveSearchWrod(word));
    },
    setOrder: (order) => {
        return dispatch(setOrder(order));
    },
    setOrderBy: (orderBy) => {
        return dispatch(setOrderBy(orderBy));
    },
    setPage: (page) => {
        return dispatch(setPage(page));
    },
    setRowsPerPage: (rowsPerPage) => {
        return dispatch(setRowsPerPage(rowsPerPage));
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
    
})


function TemplatesPage(props) {
    const [perPage, setPerPage] = React.useState(10);
    const [openRemove, setOpenRemove] = React.useState(false);
    const [removeTemplate, setRemoveTemplate] = React.useState(null);
    const [templateToUpdate, setTemplateToUpdate] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false);
    
    if(redirect)
        return <Redirect to={'/' + templateToUpdate}/>

    const handleRemoveTemplate = () => {
        const templateId = removeTemplate.id;

        service.removeTemplate(templateId).then(resp => {
            const {
                ok
            } = resp.data;

            if(!ok)
                return false;

            const templateLeft = templates.filter(template => template.id !== templateId);

            props.saveTemplates({
                templates: templateLeft,
                count: templateLeft.length
            })   

            handleCancelRemoveTemplate()
            props.openNotification({ notificationText: 'Template has been removed', notificationType: 'success' });
        })
    }

    const handleCancelRemoveTemplate = () => {
        setOpenRemove(false);
        setRemoveTemplate(null);
    }

    const askRemove = (template) => {
        setOpenRemove(true);
        setRemoveTemplate(template);
    }

    const handleRefresh = () => {
        const payload = getRequestParams({});

        service.searchTemplates(payload).then(resp => {
            const {
                ok,
                templates,
                count
            } = resp.data;

            props.saveTemplates({ templates, count });
            props.setPage(0);
            props.openNotification({ notificationText: 'Template table has been refreshed', notificationType: 'success' });
        });
        

    }

    // Data
    const {
        templates,
        count,
        searchWord,
        page,
        rowsPerPage,
        order,
        orderBy
    } = props;

    const tableData = {
        page,
        templates,
        rowsPerPage,
        count,
        order,
        orderBy
    };

    const setOrder = (order) => {
        props.setOrder(order);
    }

    const setOrderBy = (orderBy) => {
        props.setOrderBy(orderBy);
    }

    const setPage = (page) => {
        props.setPage(page);
    }

    const setRowsPerPage = (rowsPerPage) => {
        setPerPage(rowsPerPage);
        props.setRowsPerPage(rowsPerPage);
    }

    const setSearchWord = (searchWord) => {
        props.saveSearchWord(searchWord);
    }

    const getRequestParams = (addParams) => {
        const payload = {
            searchWord,
            columnName: orderBy,
            order,
            limit: rowsPerPage,
            page
        }

        return { ...payload, ...addParams };
    }

    const handleChangePage = (event, newPage) => {
        const leng = templates.length;
  
        if(leng <= (rowsPerPage * page) + rowsPerPage) {
            const payload = getRequestParams({ page: newPage });
            
            service.searchTemplates(payload).then(resp => {
                const {
                    ok,
                    templates: respTemplates,
                    count
                } = resp.data;
    
                const newTemplates = [...templates, ...respTemplates];
    
                props.saveTemplates({ templates: newTemplates, count });
                props.setPage(newPage);
            })
        }else {
            props.setPage(newPage);
        }
    };

    const handleRequestSort = (event, prop) => {
        const isAsc = orderBy === prop && order === 'asc';
        const sort = isAsc ? 'desc' : 'asc'
        setOrder(sort);
        setOrderBy(prop);

        const payload = getRequestParams({ order: sort, orderBy: prop });

        service.searchTemplates(payload).then(resp => {
            const {
                ok,
                templates,
                count
            } = resp.data;

            props.saveTemplates({ templates, count });
            props.setPage(0);
        });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (word) => {
        props.saveSearchWord(word);

        const payload = getRequestParams({ searchWord: word });

        service.searchTemplates(payload).then(resp => {
            const {
                ok,
                templates,
                count
            } = resp.data;

            props.saveTemplates({ templates, count });
            props.setPage(0);
        });

    };

    const editTemplate = (id) => {
        setTemplateToUpdate(id);
        setRedirect(true);
    }

    const tableActions = {
        handleRequestSort,
        setPage,
        setRowsPerPage,
        setSearchWord,
        handleChangePage,
        handleChangeRowsPerPage,
        askRemove,
        editTemplate
    };

    return (
        <div className='templates_list_page'>
            {openRemove && <RemoveDialog text='the template' remove={handleRemoveTemplate} close={handleCancelRemoveTemplate}/>}
            <div className='search'>
                <SearchBar search={handleSearch} searchWord={searchWord} setSearchWord={setSearchWord}/>
                <div className='actions'>
                    <Button className='action' onClick={handleRefresh}>Refresh</Button>


                    <Link to='/new'><Button className='action createBtn'>Create new</Button></Link>
                    <Link to='/custom'><Button className='action customFieldsBtn'>Custom fields</Button></Link>
                </div>
            </div>
            <TemplatesTable data={tableData} actions={tableActions}/>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplatesPage);