import {
    LOADING,
    ERROR_LOAD,
    SAVE_REACORDS,
    SAVE_SEARCH_WORD,
    SET_ORDER,
    SET_ORDER_BY,
    SET_PAGE,
    SET_ROWS_PAGE
} from './index';


export const loadingRecords = () => {
    return {
        type: LOADING,
        loadingRecords: true
    }
}

export const loadingError = () => {
    return {
        type: ERROR_LOAD,
        loadingRecords: false
    }
}

export const saveRecords = (payload) => {
    const { records, count } = payload;
    
    return {
        type: SAVE_REACORDS,
        loadingRecords: false,
        records,
        count
    }
}

export const saveSearchWrod = (word) => {
    return {
        type: SAVE_SEARCH_WORD,
        searchWord: word
    }
}

export const setOrder = (order) => {
    return {
        type: SET_ORDER,
        order
    }
}
export const setOrderBy = (orderBy) => {
    return {
        type: SET_ORDER_BY,
        orderBy
    }
}
export const setPage = (page) => {
    return {
        type: SET_PAGE,
        page
    }
}
export const setRowsPerPage = (rows) => {
    return {
        type: SET_ROWS_PAGE,
        rowsPerPage: rows
    }
}