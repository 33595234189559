import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { 
    getQuestion,
    createQuestion
} from '../../../service';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageQuestions(props) {
    const {
        close,
    } = props;

    const [question, setQuestion] = React.useState('');
    const [checkbox, setCheckbox]= React.useState('');
    const [checkboxes, setCheckboxes] = React.useState([]);
    const [selectedSection, setSelectedSection] = React.useState('');
    const [loadedData, setLoadedData] = React.useState(false);

    const [sections] = React.useState([

        // ALL
        {
            text: 'Everywhere',
            id: 'everywhere',
            part: 'All'
        },
        {
            text: 'Intestatario Info / Contract Info',
            id: 'intestatario_info_part',
            part: 'All'
        },
        {
            text: 'Delegato',
            id: 'delegato_part',
            part: 'All'
        },
        {
            text: 'Documento',
            id: 'documento_part',
            part: 'All'
        },
        {
            text: 'Punto Di Fornitura',
            id: 'punto_di_fornitura_part',
            part: 'Energia / Telco'
        },
        {
            text: 'Residenza - Sede Legale',
            id: 'side_legale_part',
            part: 'Energia / Telco'
        },

        // ENERGIA
        {
            text: 'Luce',
            id: 'luce_part',
            part: 'Energia'
        },
        {
            text: 'Gas',
            id: 'gas_part',
            part: 'Energia'
        },
        {
            text: 'Product Type',
            id: 'product_type_part',
            part: 'Energia'
        },
        {
            text: 'Pagamento E Fatturazione',
            id: 'payment_type_part',
            part: 'Energia'
        },

        // TELCO
        
        {
            text: 'Operazione',
            id: 'operazione_part',
            part: 'Telco'
        }
    ]);

    useEffect(() => {
        getQuestion().then(resp => {
            const { question: questData } = resp.data;

            const parsedRules = JSON.parse(questData.rules);

            const {
                question,
                checkboxes,
                selectedSection
            } = parsedRules;

            setQuestion(question || '');
            setCheckboxes(checkboxes || []);
            setSelectedSection(selectedSection || '');
            setLoadedData(true);
        }).catch(err => {
            console.log(err);
            setLoadedData(true);
        });
    }, [])

// errors
    const [questionError, setQuestionError] = React.useState(false);
    const [checlboxError, setCheckboxError] = React.useState(false);
    const [selectedSectionError, setSelectedSectionError] = React.useState(false);


    // useEffect(() => {
    //     if(domainsLoaded)
    //         return

    //     getDomains().then(resp => {
    //         const { ok, domains } = resp.data;

    //         setDomains(domains);
    //     })

    //     setDomainsLoaded(true);
    // })

 
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            addCheckbox()
          }
    }

    const addCheckbox = () => {
        if( !checkbox) return
        setCheckboxes([...checkboxes, checkbox]);
        setCheckbox('')
    }

    const removeCheckbox = (checkbox) => {
        // removeDomain(id).then(resp => {
        //     const { 
        //         ok,
        //     } = resp.data;

        //     if(!ok)
        //         console.log("Can't remove");
        // })

        setCheckboxes(checkboxes.filter(x => x != checkbox));
    }


      // validation
      const validate = () => {
        let questionError = false;
        let checkboxError = false;
        let selectedSectionError = false;
  
        if (!question) {
            questionError = true
        }
  
        if (checkboxes.length < 2) {
            checkboxError = true
        }

        if (!selectedSection) {
            selectedSectionError = true
        }
  
        if (questionError || checkboxError || selectedSectionError) {
           setQuestionError(questionError)
           setCheckboxError(checkboxError)
           setSelectedSectionError(selectedSectionError)
           return false;
        }
        return true;
     }

    const create = () => {
        let isValid = validate();
        if(isValid) {
            console.log('is valid')
        }

        const payload = {
            question,
            checkboxes,
            selectedSection
        };

        const jsonPayload = JSON.stringify({ ...payload });

        createQuestion({ rules: jsonPayload }).then(close).catch(err => {
            console.log(err);

            close();
        });
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="manageDomains-dialog"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Create a new</span>
            </DialogTitle>
            { loadedData && <DialogContent>
                <div className='manage_domains_popup'>
                {/* <TextField
                            className='field -regular_case'
                            label='The shown question'
                            value={domain}
                            style={{width: '100%', margin: '20px 0'}}
                            variant="outlined" 
                            onChange={(event) => setDomain(event.target.value)}
                        /> */}

                <ReactQuill style={{height: '240px', width: '100%'}}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme={"snow"}
                        onChange={(text) => setQuestion(text)}
                        defaultValue={question}
                        className={questionError && 'inputError'}
                        onFocus={() => setQuestionError(false)}
                    />

                    <div className='add_domain'>
                        <TextField
                            className={checlboxError ? 'field -regular_case inputError' : 'field -regular_case' }
                            label='Checkboxes'
                            value={checkbox}
                            variant="outlined" 
                            onKeyPress={handleKeyPress}
                            onFocus={() => setCheckboxError(false)}
                            onChange={(event) => setCheckbox(event.target.value)}
                        />
                        <div className='action' onClick={addCheckbox}>+</div>
                    </div>
                    {checkboxes.length > 0 &&
                    <div className='domain_list'>
                        <div className='head_domain'>
                            <div className='label'>Name</div>
                            <div className='label'>Actions</div>
                        </div>
                        
                        <div className='list'>
                            {checkboxes.map(checkbox => {
                                return <div className='domain'>
                                    <div className='name'>{checkbox}</div>
                                    <div className='actions'>
                                        <Tooltip title='Remove'>
                                            <IconButton className='action' onClick={() => { removeCheckbox(checkbox) }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            })}
                            
                        </div>

                    </div>
}

                    <FormControl variant="outlined" className={selectedSectionError ? 'field -select inputError' : 'field -select'}  style={{width: '100%', margin: '20px 0'}}>
                        <InputLabel id="demo-simple-select-outlined-label">Section</InputLabel>
                        <Select
                            label="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={selectedSection}
                            displayEmpty
                            className='field -select'
                            label="Section"
                            helperText='Section'
                            onFocus={() => setSelectedSectionError(false)}
                            onChange={(event) => setSelectedSection(event.target.value)}
                            >
                                {sections.map(x => (
                                    <MenuItem value={x.id}>{x.text} ({x.part})</MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                </div>
            </DialogContent> }
            <DialogActions>
            <Button className='user_create_dialog_action -generate' onClick={close}>
                Close
            </Button>
            <Button className='user_create_dialog_action -close' onClick={create}>
                Update
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}

const Editor = {
    modules: {
        toolbar: [
          [{'header': []}, {'color': []}], // , {'color': []}, {'background': []}
          ['bold', 'italic', 'underline', 'blockquote'],
          [{'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link'],
          ['clean']
        ],
      },
    
      formats: [
        'header',
        'color',
        // 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ],
}