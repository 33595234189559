// auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// Records
export const LOADING = "LOADING";
export const ERROR_LOAD = "ERROR_LOAD";
export const SAVE_REACORDS = "SAVE_REACORDS";
export const SAVE_SEARCH_WORD = "SAVE_SEARCH_WORD";
export const SET_ORDER = "SET_ORDER";
export const SET_ORDER_BY = "SET_ORDER_BY";
export const SET_PAGE = "SET_PAGE";
export const SET_ROWS_PAGE = "SET_ROWS_PAGE";

// Notification
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

// Custom fields
export const LOADING_CUSTOM = "LOADING_CUSTOM";
export const SAVE_CUSTOM = "SAVE_CUSTOM";

// Loader
export const SET_LOADER = "SET_LOADER";

export const FIELDS = {
    SAVE_FIELDS: 'SAVE_FIELDS'
}

export const PACKAGES = {
    SAVE_PACKAGES: 'SAVE_PACKAGES'
};