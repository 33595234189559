import React, { Component } from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';

import TemplatesPage from './list';
import CreateTemplate from './new';
import Custom from './custom';

function Templates() {
    return (
        <HashRouter basename='/templates'>
            <Switch>
                <Route path='/new' component={CreateTemplate}/>
                <Route path='/custom' component={Custom}/>
                <Route path='/:id' component={CreateTemplate}/>
                <Route path='/' component={TemplatesPage}/>
            </Switch>
        </HashRouter>
    )
}

export default Templates