import React from 'react';
import {
    Button,
    TextField,
    Tooltip,
    Icon,
    Radio,
    MenuItem
} from '@material-ui/core';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';

import './index.scss';
 
class LocationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }
 
  handleChange = (address) => {
    this.setState({ address });
  };
 
  handleSelect = (address, placeId) => {
      // this.setState({ address });
    
    geocodeByPlaceId(placeId)
      .then(results => { 
        console.log(results);
        const payload = {
          city: getCity(results[0]),
          street: getStreet(results[0]),
          postCode: getPostCode(results[0]),
          state: getState(results[0]),
          countryShort: getCountryShort(results[0]),
          country: getCountry(results[0]),
          streetNumber: getStreetNumber(results[0]),
          district: getDistrict(results[0])
        }

        const postalCode = getPostCode(results[0]);

        const modifiedAddress = postalCode ? address + ', ' + postalCode : address;

        this.setState({ address: modifiedAddress });

        return this.props.setAddress(payload, getCity(results[0]));
      })
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
  };
 
  render() {
    return (
      <PlacesAutocomplete
        debounce={1100}
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          
          <div className={true ? 'search_block' : 'search_block disabled'}>
              
            <TextField
                className='address-select'
                label={this.props.name}
                inputProps={{
                  autocomplete: 'new-address',
                  name: this.props.nameField,
                  form: {
                    autocomplete: 'off',
                  },
                }}
              {...getInputProps()}
            />
            <div className={suggestions[0] ? 'autocomplete_dropdown_container' : 'hide'}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {

                const className = suggestion.active
                  ? 'suggestion_item -active'
                  : 'suggestion_item';
                  
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearch;

function getPlaceId(place) {
  if (!isGooglePlace(place))
    return;
  return place.place_id;
}

function getStreetNumber(place) {
  var COMPONENT_TEMPLATE = { street_number: 'short_name' },
      streetNumber = getAddrComponent(place, COMPONENT_TEMPLATE);
  return streetNumber;
}

function getStreet(place) {
  var COMPONENT_TEMPLATE = { route: 'long_name' },
      street = getAddrComponent(place, COMPONENT_TEMPLATE);
  return street;
}

function getCity(place) {
  var COMPONENT_TEMPLATE = { locality: 'long_name' },
      city = getAddrComponent(place, COMPONENT_TEMPLATE);
  return city;
}

function getState(place) {
  var COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' },
      state = getAddrComponent(place, COMPONENT_TEMPLATE);
  return state;
}

function getCountryShort(place) {
  var COMPONENT_TEMPLATE = { country: 'short_name' },
      countryShort = getAddrComponent(place, COMPONENT_TEMPLATE);
  return countryShort;
}

function getCountry(place) {
  var COMPONENT_TEMPLATE = { country: 'long_name' },
      country = getAddrComponent(place, COMPONENT_TEMPLATE);
  return country;
}

function getPostCode(place) {
  var COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = getAddrComponent(place, COMPONENT_TEMPLATE);
  return postCode;
}

function getDistrict(place) {
  var COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' },
      state = getAddrComponent(place, COMPONENT_TEMPLATE);
  return state;
}

function isGooglePlace(place) {
  if (!place)
    return false;
  return !!place.place_id;
}

function getAddrComponent(place, componentTemplate) {
  let result;
  if (!isGooglePlace(place))
    return;
  for (let i = 0; i < place.address_components.length; i++) {
    const addressType = place.address_components[i].types[0];
    if (componentTemplate[addressType]) {
      result = place.address_components[i][componentTemplate[addressType]];
      return result;
    }
  }
  return;
}