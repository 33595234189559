import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import PreviewScript from '../dialogs/previewScript';
import SyncPopup from '../dialogs/showSyncPopup';
import DupPopup from '../dialogs/showDupPopup';
import CreatePDF from '../../components/dialogs/createPdf';

import moment from 'moment';
import Twig from 'twig';

import store from '../../store';

import { 
  openNotification
} from '../../actions/notification';

import { 
  syncRecord,
  syncAllRecord,
  sendEmailZoho,
  sendNamirial
} from '../../service';

import './index.scss';

const headCells = [
  { id: 'last_name', numeric: false, disablePadding: false, label: 'Cognome' },
  { id: 'name', numeric: true, disablePadding: false, label: 'Nome' },
  { id: 'ragione_sociale', numeric: true, disableSort: true, disablePadding: false, label: 'Ragione Sociale' },
  { id: 'contact_owner', numeric: true, disablePadding: false, label: 'Contact Owner' },
  { id: 'operation_gas', numeric: true, disablePadding: false, label: 'Operazione GAS' },
  { id: 'operation_luce', numeric: true, disablePadding: false, label: 'Operazione EE' },
  { id: 'type_contract', numeric: true, disablePadding: false, label: 'Type of Contract' },
  { id: 'updatedAt', numeric: true, disablePadding: false, label: 'Last Mofidied' },
  { id: 'actions', disableSort: true, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className='head_cell'
          >
              { !headCell.disableSort 
                ?
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                    >
                        {headCell.label}
                    </TableSortLabel>
                : 
                    headCell.label
              }
              
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function RecordsTable(props) {
  const [preview, setPreview] = React.useState(false);
  const [textMessage, setTextMessage] = React.useState('');
  const [textMessageSecond, setTextMessageSecond] = React.useState('');

  const [download, setDownload] = React.useState(false);
  const [showSyncPopup, setShowSyncPopup] = React.useState(false);
  const [showDupPopup, setShowDupPopup] = React.useState(false);
  const [typesPdf, setTypesPdf] = React.useState([]);
  const [downloadId, setDownloadId] = React.useState(null);
  const [syncId, setSyncId] = React.useState(null);
  const [dupId, setDupId] = React.useState(null);
  const [syncStatus, setSyncStatus] = React.useState(null);
  const [syncAllStatus, setSyncAllStatus] = React.useState(null);
  

  const openPreview = (record, textMessage = '', textMessageSecond = '') => {
    setPreview(true);

    const template = Twig.twig({ data: textMessage });
    const templateSecond = Twig.twig({ data: textMessageSecond });

    const randomRecord = {...record, ...record.data};

    console.log(randomRecord);

    randomRecord.luce_pod = randomRecord.pod;
    randomRecord.gas_pdr = randomRecord.pdr;

    const preparedMessage = template.render(randomRecord);
    const preparedMessageSecond = templateSecond.render(randomRecord);

    setTextMessage(preparedMessage);
    setTextMessageSecond(preparedMessageSecond);
  }

  const openDownload = (id, types) => {
    setDownload(true);
    setTypesPdf(types);
    setDownloadId(id);
  }

  const dupRec = () => {
    duplicateRecord(dupId)
  }

  const doSync = () => {
    const id = syncId;

    setSyncStatus(id)

    syncRecord(id).then(resp => {
      const {
        fatId, 
        resId, 
        putId, 
        contId, 
        delegId,
        delegExtraId,
        businessId,
        dealId, 
        contractGasId, 
        contractLuceId, 
        contractExtraId, 
        podGasId, 
        podLuceId, 
        podExtraId, 
        contractDetGasId, 
        contractDetLuceId, 
        contractDetExtraId, 
        podId,
        contractId,
        contractDetId,
        bankId,
        contactPrecId,
        addressPrecId,
        bankContactId,
        respData
      } = resp.data;
  
      setSyncStatus(null);
      setSyncId(null);
      setShowSyncPopup(null);
  
      respData.map(module => {
        console.log(module);
      })
  
      // if(fatId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule1/' + fatId, '_blank');
  
      // if(resId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule1/' + resId, '_blank1');
  
      // if(putId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule1/' + putId, '_blank2');
  
      // if(addressPrecId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule1/' + addressPrecId, 'address_prec');
  
      if(contId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + contId, '_blank3');
  
      // if(bankContactId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + bankContactId, '_bank_contact');
  
      // if(bankId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule4/' + bankId, '_bank_id');
  
      // if(contactPrecId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + contactPrecId, '_cont_prec');
  
      // if(delegId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + delegId, '_blank315');
  
      // if(delegExtraId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + delegId, 'deleg_extra');
  
      // if(businessId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + businessId, '_blank325');
  
      // if(dealId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Potentials/' + dealId, '_blank4');
  
      // if(podGasId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule3/' + podGasId, 'fwdfsdg11');
  
      // if(podId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule3/' + podId, '_blank5');
      
      //   if(podLuceId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule3/' + podLuceId, '_blank31');
  
      // if(podExtraId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule3/' + podExtraId, '_blank132');
        
      if(contractGasId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule5/' + contractGasId, '_gwba23r');
  
      if(contractId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule5/' + contractId, '_g4gz');
  
      if(contractLuceId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule5/' + contractLuceId, '_45h2');
  
      if(contractExtraId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule5/' + contractExtraId, '_asgq2');
  
      // if(contractDetGasId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule6/' + contractDetGasId, '_blank14sdf');
  
      // if(contractDetId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule6/' + contractDetId, '_blank63');
  
      // if(contractDetLuceId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule6/' + contractDetLuceId, '_blank23');
  
      // if(contractDetExtraId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule6/' + contractDetExtraId, '_blank53');
    }).catch(err => {
      console.log(err);
      store.dispatch(openNotification({ notificationText: 'Cannot do the sync & create modules for the record!', notificationType: 'error' }))
      setSyncStatus(null);
      setSyncId(null);
      setShowSyncPopup(null);
    });
  }
  
    const { 
      records,
      count,
      order,
      orderBy,
      page,
      rowsPerPage,
      searchWord
    } = props.data;

    const {
      handleRequestSort,
      handleChangePage,
      handleChangeRowsPerPage,
      setPage,
      setRowsPerPage,
      askRemove,
      editRecord,
      duplicateRecord
    } = props.actions;
  
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);
  
    return (
      <div className='table_records'>
        {preview && <PreviewScript textMessage={textMessage} textMessageSecond={textMessageSecond} close={() => setPreview(false)}/>}
        {download && <CreatePDF id={downloadId} types={typesPdf} close={() => setDownload(false)}/>}
        {showSyncPopup && <SyncPopup syncAction={doSync} close={() => setShowSyncPopup(false)}/>}
        {showDupPopup && <DupPopup syncAction={dupRec} close={() => setShowDupPopup(false)}/>}
        <Paper className='table'>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size='medium'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={count}
              />
              <TableBody>
                {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {

                    const ragione_sociale = row.data.ragione_sociale;
  
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        className={row.draft ? '-draft' : ''}
                      >
                        
                        <TableCell className='body_cell -last_name' padding='default' scope="row">
                          {row.last_name}
                        </TableCell>
                        <TableCell className='body_cell -name'>
                          {row.name}
                        </TableCell>
                        <TableCell className='body_cell -name'>
                          {ragione_sociale ? ragione_sociale : ''}
                        </TableCell>
                        <TableCell className='body_cell -owner'>
                          {row.contact_owner}
                        </TableCell>
                        <TableCell className='body_cell -op_gas'>
                          {row.operation_gas}
                        </TableCell>
                        <TableCell className='body_cell -op_ee'>
                          {row.operation_luce}
                        </TableCell>
                        <TableCell className='body_cell -type'>
                          {row.type_contract}
                        </TableCell>
                        <TableCell className='body_cell -date'>
                          {
                            moment(row.updatedAt).format('DD/MM/YYYY hh:mm')
                          }
                        </TableCell>
                        <TableCell padding='none' className='table_actions'>
                          {renderActions(
                            row,
                            askRemove,
                            editRecord, 
                            openPreview,
                            openDownload,
                            duplicateRecord,
                            syncStatus,
                            setSyncStatus,
                            syncAllStatus, 
                            setSyncAllStatus,
                            setShowSyncPopup, 
                            setSyncId,
                            sendEmail,
                            setDupId,
                            setShowDupPopup
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }

export default RecordsTable

const renderActions = (row, askRemove, editRecord, openPreview, openDownload, duplicateRecord, syncStatus, setSyncStatus, syncAllStatus, setSyncAllStatus, setShowSyncPopup, setSyncId, sendEmail, setDupId, setShowDupPopup) => {
  const send_email = store.getState().auth.send_email;

  const {
    operation_l = null,
    operation_g = null,
    data: {
      product_ee,
      product_gas
    }
  } = row;

  let message = null;
  let secondMessage = null;
  const types = [];

  const regExpIrProd = new RegExp(/^IR/gmi);

  if(!(regExpIrProd.test(product_ee?.product_name) || regExpIrProd.test(product_gas?.product_name))) {
    if(operation_l)
      types.push('luce');
  
    if(operation_g)
      types.push('gas');
  
    if(operation_l && operation_g)
      types.push('dual');
  }

  const status = (operation_l || operation_g) ? (operation_l && operation_g) ? "Dual" : 'Single' : null;

  switch(status) {
    case 'Dual': 
      message = operation_l.script;
      secondMessage = operation_g.script;
      break;

    case 'Single':
      message = operation_l ? operation_l.script : operation_g.script ? operation_g.script : null; 
      break;
  };

  const draft = row.draft;

  return (
    <div className='actions'>
      <Tooltip title='edit' placement="top">
        <div className='action -edit' onClick={() => editRecord(row.id)}></div>
      </Tooltip>
      { status && !draft &&  <Tooltip title='Download & Create PDF' placement="top">
        <div className='action -download' onClick={() => openDownload(row.id, types)}></div>
      </Tooltip>}
      { status && !draft && <Tooltip title='Script' placement="top">
        <div className='action -script' onClick={() => openPreview(row, message, secondMessage)}></div>
      </Tooltip>}
      { send_email && status && types[0] && !draft && <Tooltip title='Electronic signature' placement="top" className={ (syncStatus === row.id) ? '-disabled': ''}>
        <div className='action -e_sign' onClick={() => sendNamirialAction(row.id, setSyncAllStatus)}></div>
      </Tooltip> }
      { !draft && <Tooltip title='Sync Zoho & Create Potential' className={ (syncStatus === row.id) ? '-disabled': ''} onClick={() => syncRecordAction(row.id, setSyncStatus, row.sync_data, setShowSyncPopup, setSyncId )} placement="top">
        <div className='action -sync_pot'></div>
      </Tooltip> }
      { !draft && <Tooltip title='Sync Zoho' className={ (syncAllStatus === row.id) ? '-disabled': ''} onClick={() => syncAllRecordAction(row.id, setSyncAllStatus)} placement="top">
        <div className='action -sync'></div>
      </Tooltip> }
      {/* { send_email && !draft && <Tooltip title='Send email' className={ (syncAllStatus === row.id) ? '-disabled': ''} onClick={() => sendEmail(row.id, setSyncAllStatus)} placement="top">
        <div className='action -send_email'></div>
      </Tooltip> } */}
      { <Tooltip title='View on Zoho' onClick={() => {

        window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + row.contact_id, '_contact_blank');

      }} className={ (!row.contact_id) ? '-disabled': ''} placement="top">
        <div className='action -view'></div>
      </Tooltip>}
      <Tooltip title='Duplicate Record' placement="top">
        <div className='action -duplicate' onClick={() => {
            // duplicateRecord(row.id)
            setShowDupPopup(true);
            setDupId(row.id);
          }}></div>
      </Tooltip>
      <Tooltip title='Remove' placement="top">
        <div className='action -remove' onClick={() => askRemove(row)}></div>
      </Tooltip>
    </div>
  )
}

const sendNamirialAction = (id, cb) => {
  cb(id);
  store.dispatch(openNotification({ notificationText: 'Sending the contract...', notificationType: 'success' }))

  sendNamirial(id).then(resp => {
    store.dispatch(openNotification({ notificationText: 'The contract sent!', notificationType: 'success' }))
  }).catch(err => {
    console.log(err);

    store.dispatch(openNotification({ notificationText: 'Cannot send the contract!', notificationType: 'success' }))
  });
}

const sendEmail = (id, cb) => {
  cb(id);

  sendEmailZoho(id).then(resp => {
    cb(null);

    const { integrationId, contactId } = resp.data;

    if(!integrationId)
      return store.dispatch(openNotification({ notificationText: 'Email did not send!', notificationType: 'success' }))

    store.dispatch(openNotification({ notificationText: 'Email send!', notificationType: 'success' }))
  }).catch(error => {
    console.log(error);

    cb(null);
    store.dispatch(openNotification({ notificationText: 'Email did not send!', notificationType: 'success' }))
  });
}



const syncAllRecordAction = (id, cb) => {
  cb(id);

  console.log(id, "start")

  syncAllRecord(id).then(resp => {
    const { ok } = resp.data;

    if(ok)
      store.dispatch(openNotification({ notificationText: 'Sync success!', notificationType: 'success' }))
    else 
      store.dispatch(openNotification({ notificationText: 'Cannot sync the record!', notificationType: 'error' }))

    cb(null);
  }).catch(err => {
    store.dispatch(openNotification({ notificationText: 'Cannot sync the record!', notificationType: 'error' }))
    cb(null);
  })
};

const syncRecordAction = (id, setSyncStatus, sync_data, showSyncPopup, setSyncId) => {
  if(sync_data) {
    setSyncId(id);
    showSyncPopup(true);
  } else {
    setSyncStatus(id)
    syncRecord(id).then(resp => {
      const {
        fatId, 
        resId, 
        putId, 
        contId, 
        delegId,
        delegExtraId,
        businessId,
        dealId, 
        contractGasId, 
        contractLuceId, 
        contractExtraId, 
        podGasId, 
        podLuceId, 
        podExtraId, 
        contractDetGasId, 
        contractDetLuceId, 
        contractDetExtraId, 
        podId,
        contractId,
        contractDetId,
        bankId,
        contactPrecId,
        addressPrecId,
        bankContactId,
        respData
      } = resp.data;

      setSyncStatus(null)

      respData.map(module => {
        console.log(module);
      })

      // if(fatId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule1/' + fatId, '_blank');

      // if(resId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule1/' + resId, '_blank1');

      // if(putId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule1/' + putId, '_blank2');

      // if(addressPrecId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule1/' + addressPrecId, 'address_prec');

      if(contId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + contId, '_blank3');

      // if(bankContactId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + bankContactId, '_bank_contact');

      // if(bankId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule4/' + bankId, '_bank_id');

      // if(contactPrecId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + contactPrecId, '_cont_prec');

      // if(delegId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + delegId, '_blank315');

      // if(delegExtraId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + delegId, 'deleg_extra');

      // if(businessId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Contacts/' + businessId, '_blank325');

      // if(dealId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/Potentials/' + dealId, '_blank4');

      // if(podGasId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule3/' + podGasId, 'fwdfsdg11');

      // if(podId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule3/' + podId, '_blank5');
      
      //   if(podLuceId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule3/' + podLuceId, '_blank31');

      // if(podExtraId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule3/' + podExtraId, '_blank132');
        
      if(contractGasId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule5/' + contractGasId, '_gwba23r');

      if(contractId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule5/' + contractId, '_g4gz');

      if(contractLuceId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule5/' + contractLuceId, '_45h2');

      if(contractExtraId)
        window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule5/' + contractExtraId, '_asgq2');

      // if(contractDetGasId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule6/' + contractDetGasId, '_blank14sdf');

      // if(contractDetId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule6/' + contractDetId, '_blank63');

      // if(contractDetLuceId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule6/' + contractDetLuceId, '_blank23');

      // if(contractDetExtraId)
      //   window.open('https://crm.zoho.com/crm/org52021474/tab/CustomModule6/' + contractDetExtraId, '_blank53');
    }).catch(err => {
      console.log(err);
      store.dispatch(openNotification({ notificationText: 'Cannot do the sync & create modules for the record!', notificationType: 'error' }))
      setSyncStatus(null);
    });
  }
  
}


{/* <TableCell className='body_cell -last_name' padding='default' scope="row">
  <Tooltip title={row.last_name} placement="top">
    <span>{row.last_name}</span>
  </Tooltip>
</TableCell>
<TableCell className='body_cell -name'>
  <Tooltip title={row.name} placement="top">
    <span>{row.name}</span>
  </Tooltip>
</TableCell>
<TableCell className='body_cell -owner'>
  <Tooltip title={row.contact_owner} placement="top">
    <span>{row.contact_owner}</span>
  </Tooltip>
</TableCell>
<TableCell className='body_cell -op_gas'>
  <Tooltip title={row.operation_gas} placement="top">
    <span>{row.operation_gas}</span>
  </Tooltip>
</TableCell>
<TableCell className='body_cell -op_ee'>
  <Tooltip title={row.operation_luce} placement="top">
    <span>{row.operation_luce}</span>
  </Tooltip>
</TableCell>
<TableCell className='body_cell -type'>
  <Tooltip title={row.type_contract} placement="top">
    <span>{row.type_contract}</span>
  </Tooltip>
</TableCell> */}


// `curl -c ./cookie -s -L "https://drive.google.com/uc?export=download&id=z-YuEeZCa6WG3bWVQReS_eFrCSwdDAJT" > ${tempDir} curl -Lb ./cookie "https://drive.google.com/uc?export=download&confirm=awk '${tempDir} {print $NF}' ./cookie&id=z-YuEeZCa6WG3bWVQReS_eFrCSwdDAJT" -o burpsuite_community_v2020.2.1.jar rm cookie`