import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router-dom';
import store from '../../../store';

import { useDrop } from 'react-dnd';
import { useDrag } from 'react-dnd';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { 
    saveTemplates
} from '../../../actions/templates';

import './index.scss';

import { 
    openNotification
} from '../../../actions/notification';

import * as service from '../../../service';

const mapStateToProps = state => ({
    ...state.scripts
})

const mapDispatchToProps = dispatch => ({
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
})

function CreateeTemplate(props) {
    const [name, setName] = React.useState('');
    const [pdfFile, setPdfFile] = React.useState(null);
    const [usedPdfFile, setUsedPdfFile] = React.useState('');
    const [templateLoaded, setTemplateLoaded] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [separator, setSeparator] = React.useState('');

    const [words, setWords] = React.useState(listOfNames);
    const [selectedWords, setSelectedWords] = React.useState([]);
    const [customWord, setCustomWord] = React.useState('');

    const [config, setConfig] = React.useState('');


    const addCustomWord = () =>  {
        setWords([customWord, ...words]);
        setCustomWord("");
    }
    const onCustomWordChange = (e) => {
        setCustomWord(e.target.value)
    }
    
    const addWord = (word) => {
        setSelectedWords([...selectedWords, word])
        let droppedWord = words.find(x => x === word)
        setWords([...words.filter(x => x !== droppedWord)])
    }

    const deleteWord = (word) => {
        let deletedWord = selectedWords.find(x => x === word)
        setSelectedWords([...selectedWords.filter(x => x !== deletedWord)])
        setWords([...words, word])
    }

    const id = props.match.params.id;

    useEffect(() => {
        const fetchData = async () => {
            service.getTemplateById(id).then(resp => {
                const {
                    ok,
                    template
                } = resp.data;

                if(!ok) 
                    return false;

                const naming = template.naming_rules ? template.naming_rules.split(',') : [];

                const listOfWords = naming[0] ? listOfNames.filter(name => !naming.some(word => word === name)) : listOfNames; 

                setName(template.name);
                setUsedPdfFile(template.filename)
                setSeparator(template.separator)
                setSelectedWords(naming)
                setWords(listOfWords);
                setConfig(template.config || '');
            })
        };
        
        if(id)
            fetchData();
    }, []);
    
    if(redirect)
        return <Redirect to='/'/>


    const createTemplate = () => {
        const templates = store.getState().templates.templates;

        const payload = {
            name,
            naming_rules: selectedWords.join(),
            separator,
            config
        };

        service.createTemplate(payload).then(resp => {
            const {
                ok,
                template,
            } = resp.data;

            if(!ok)
                return;

            const templateId = template.id;
            const formData = new FormData;

            formData.append('files', pdfFile);

            if(!pdfFile)
                return false;
                
            service.uploadFileTemplate(templateId, formData).then(resp => {
                const {
                    ok, 
                    template
                } = resp.data;

                templates.unshift(template);
                store.dispatch(saveTemplates({ templates, count: templates.length }));
                setRedirect(true);
            })

            props.openNotification({ notificationText: 'Template has been created', notificationType: 'success' });
        })
    }

    const handleUpdate = () => {
        const templates = store.getState().templates.templates;

        const payload = {
            name,
            naming_rules: selectedWords.join(),
            separator,
            config
        };

        service.updateTemplate(id, payload).then(resp => {
            const {
                ok,
            } = resp.data;

            if(!ok)
                return;

            if(pdfFile){
                const formData = new FormData;

                formData.append('files', pdfFile);
                    
                service.uploadFileTemplate(id, formData).then(resp => {
                    const {
                        ok, 
                        template,
                        filename
                    } = resp.data;

                    const updatedTemplate = {
                        id,
                        name,
                        filename,
                        updatedAt: + new Date()
                    }
    
                    const updatedTemplates = templates.map(template => {
                        if(id != template.id) {
                            return template;
                        }else {
                            return updatedTemplate
                        }
                    })
    
                    store.dispatch(saveTemplates({ templates: updatedTemplates }));
                    setRedirect(true);
                })
            } else {
                const updatedTemplate = {
                    id,
                    name,
                    filename: usedPdfFile,
                    updatedAt: + new Date()
                };

                const updatedTemplates = templates.map(template => {
                    if(id != template.id) {
                        return template;
                    }

                    return updatedTemplate
                });

                store.dispatch(saveTemplates({ templates: updatedTemplates }));
                setRedirect(true);
            }

            props.openNotification({ notificationText: 'Template has been updated', notificationType: 'success' });

        })
    }

    const DropField = () => {
        const [{ canDrop, isOver }, drop] = useDrop({
            accept: "box",
            drop: () => ({ name: 'DropField' }),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        });
        const isActive = canDrop && isOver;

        return (
            <div ref={drop} className={isActive ? "dropField-active dropField-field" : "dropField-field"}>
                {selectedWords.map(word => (
                        <div className="dropField-selectedWord" onClick={() => deleteWord(word)}>
                            {word}
                        </div>
                    ))}
            </div>
        )
    };

    const Box = ({ word }) => {
        const [{ isDragging }, drag] = useDrag({
            item: { word, type: "box" },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (item && dropResult) {
                    setSelectedWords([...selectedWords, item.word])
                    let droppedWord = words.find(x => x === item.word)
                    setWords([...words.filter(x => x !== droppedWord)])
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        return (<div ref={drag} className="side-container-word" onClick = {() => addWord(word)}>
                {word}
            </div>);
    };
    

    return (
        <div className='templates_create_page'>
            <DndProvider backend={HTML5Backend}>
            <div className = "content_wrapper">
                <div className='fields_section'>
                    <div className='section'>
                        <div className='head_text'>PDF Template Properties</div>
                        <div className='fields'>
                            <TextField 
                                className='field -regular_case' 
                                onChange={event => setName(event.target.value)} 
                                required label="Name"
                                value={name} />
                        </div>

                        <div className='fields'>
                            <div className='upload'>
                                <input
                                    accept="application/pdf"
                                    id="contained-button-file"
                                    type="file"
                                    className='field'
                                    onChange={(event) => setPdfFile(event.target.files[0])}
                                />

                                <label htmlFor="contained-button-file" className='action'>
                                    <Button component="span">
                                        Upload PDF
                                    </Button>
                                </label>


                                <div className='file_name'>
                                    {pdfFile &&  pdfFile.name}
                                </div>
                            </div>
                        </div>
                        <div className="dropField-containerWrapper">
                            <div className="dropField-container">
                                <div className="dropField-label">PDF naming rules</div>
                                <DropField />
                            </div>
                        </div>

                        <div className='fields'>
                            <TextField
                                className='field -regular_case -full'
                                id="outlined-multiline-static"
                                label="Configs"
                                multiline
                                rows={8}
                                value={config}
                                onChange={event => setConfig(event.target.value)}
                                variant="outlined"/>
                    </div>
                    </div>
                </div>

                <div class="side-container-wrapper">
                    <div class="side-container-addWordWrapper">
                        <input 
                            type="text" 
                            placeholder="Add custom word" 
                            value={customWord} 
                            className="side-container-addWordInput"  
                            onChange={onCustomWordChange} />
                        <div className={customWord != 0 ? "side-container-addWordBtn" : "side-container-addWordBtn-disable"} onClick={addCustomWord}>add</div>
                    </div>

                    <br/>
                    <br/>

                    <div className='fields -center separatorInput'>
                            <TextField 
                                className='field -regular_case' 
                                onChange={event => setSeparator(event.target.value)} 
                                label="Separator"
                                value={separator} />
                    </div>

                    <div className="side-container-wordsWrapper">
                        {words.map(word => (
                            <Box word={word}/>
                        ))}
                    </div>
                </div>
            </div>
            <div className='actions'>
                {!id 
                    ? 
                    <Button onClick={createTemplate} className='action'>
                        Add
                    </Button>
                    :
                    <Button onClick={handleUpdate} className='action'>
                        Update
                    </Button>
                }
            </div>
            </DndProvider>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateeTemplate);


const listOfNames =  [
    'Contratto',
    '$Nome',
    '$Cognome',
    '$ContractId',
    '$POD',
    '$PDR',
    '$CF',
    '$Email',
    '$RAGIONE_SOCIALE'
];