import {
    LOADING_CUSTOM,
    SAVE_CUSTOM
} from '../actions';

const defaultState = {
    loadingCustoms: false,
    customs: [],
};

export default (state = {...defaultState}, action) => {
    const {
        type,
        loadingCustoms,
        customs
    } = action;

    switch (type) {
        case LOADING_CUSTOM:
            {
            return {
                ...state,
                loadingCustoms
            }
        }

        case SAVE_CUSTOM: {
            return {
                ...state,
                customs,
            }

        }

        default:
            return state;
    }
}