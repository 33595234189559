import React, { Component } from 'react';
import { 
    TextField,
    Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import RecordsTable from '../../../components/table';
import SearchBar from '../../../components/table/searchBar';
import RemoveDialog from '../../../components/dialogs/remove';

import PreviewScript from '../../../components/dialogs/previewScript';

import { 
    loadingRecords,
    loadingError,
    saveRecords,
    saveSearchWrod,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage
} from '../../../actions/records';

import { 
    openNotification
} from '../../../actions/notification';


import { debounce } from '../../../libs/ownScripts';
import * as service from '../../../service';


import './index.scss';

const mapStateToProps = state => ({
    ...state.records
})

const mapDispatchToProps = dispatch => ({
    saveRecords: (payload) => {
        return dispatch(saveRecords(payload));
    },
    saveSearchWord: (word) => {
        return dispatch(saveSearchWrod(word));
    },
    setOrder: (order) => {
        return dispatch(setOrder(order));
    },
    setOrderBy: (orderBy) => {
        return dispatch(setOrderBy(orderBy));
    },
    setPage: (page) => {
        return dispatch(setPage(page));
    },
    setRowsPerPage: (rowsPerPage) => {
        return dispatch(setRowsPerPage(rowsPerPage));
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
})


function ReacordsPage(props) {
    const [perPage, setPerPage] = React.useState(10);
    const [openRemove, setOpenRemove] = React.useState(false);
    const [removeRecord, setRemoveRecord] = React.useState(null);
    const [recordToUpdate, setRecordToUpdate] = React.useState(null);
    const [recordToDuplicate, setRecordToDuplicate] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false);
    const [preview, setPreview] = React.useState(false);

    if(redirect)
        return <Redirect to={'/' + ((recordToUpdate) ? recordToUpdate : recordToDuplicate + '/duplicate')}/>

    // Data
    const {
        records,
        count,
        searchWord,
        page,
        rowsPerPage,
        order,
        orderBy
    } = props;

    const tableData = {
        page,
        records,
        rowsPerPage,
        count,
        order,
        orderBy
    };

    const editRecord = (id) => {
        setRecordToUpdate(id);
        setRedirect(true);
    }

    const duplicateRecord = (id) => {
        setRecordToDuplicate(id);
        setRedirect(true);
    }

    const handleRemoveRecord = () => {
        const recordId = removeRecord.id;

        service.removeRecord(recordId).then(resp => {
            const {
                ok
            } = resp.data;

            if(!ok)
                return false;

            const recordLeft = records.filter(record => record.id !== recordId);

            props.saveRecords({
                records: recordLeft,
                count: recordLeft.length
            })

            handleCancelRemoveRecord()
            props.openNotification({ notificationText: 'Record table has been removed', notificationType: 'success' });
        })
    }

    const handleCancelRemoveRecord = () => {
        setOpenRemove(false);
        setRemoveRecord(null);
    }

    const askRemove = (record) => {
        setOpenRemove(true);
        setRemoveRecord(record);
    }

    const setOrder = (order) => {
        props.setOrder(order);
    }

    const setOrderBy = (orderBy) => {
        props.setOrderBy(orderBy);
    }

    const setPage = (page) => {
        props.setPage(page);
    }

    const setRowsPerPage = (rowsPerPage) => {
        setPerPage(rowsPerPage);
        props.setRowsPerPage(rowsPerPage);
    }

    const setSearchWord = (searchWord) => {
        props.saveSearchWord(searchWord);
    }

    const getRequestParams = (addParams) => {
        const payload = {
            searchWord,
            columnName: orderBy,
            order,
            limit: rowsPerPage,
            page
        }

        return { ...payload, ...addParams };
    }

    const handleChangePage = (event, newPage) => {
        const leng = records.length;
  
        if(leng <= (rowsPerPage * page) + rowsPerPage) {
            const payload = getRequestParams({ page: newPage });
            
            service.searchRecords(payload).then(resp => {
                const {
                    ok,
                    records: respRecords,
                    count
                } = resp.data;
    
                const newRecords = [...records, ...respRecords];
    
                props.saveRecords({ records: newRecords, count });
                props.setPage(newPage);
            })
        }else {
            props.setPage(newPage);
        }
    };

    const handleRequestSort = (event, prop) => {
        const isAsc = orderBy === prop && order === 'asc';
        const sort = isAsc ? 'desc' : 'asc'
        setOrder(sort);
        setOrderBy(prop);

        const payload = getRequestParams({ order: sort, orderBy: prop });

        service.searchRecords(payload).then(resp => {
            const {
                ok,
                records,
                count
            } = resp.data;

            props.saveRecords({ records, count });
            props.setPage(0);
        });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (word) => {
        props.saveSearchWord(word);

        const payload = getRequestParams({ searchWord: word });

        service.searchRecords(payload).then(resp => {
            const {
                ok,
                records,
                count
            } = resp.data;

            props.saveRecords({ records, count });
            props.setPage(0);
        });

    };

    const handleRefresh = () => {
        const payload = getRequestParams({});

        service.searchRecords(payload).then(resp => {
            const {
                ok,
                records,
                count
            } = resp.data;

            props.saveRecords({ records, count });
            props.setPage(0);
            props.openNotification({ notificationText: 'Record table has been refreshed', notificationType: 'success' });
        });
        
    }

    const tableActions = {
        handleRequestSort,
        setPage,
        setRowsPerPage,
        setSearchWord,
        handleChangePage,
        handleChangeRowsPerPage,
        askRemove,
        editRecord,
        duplicateRecord
    };

    return (
        <div className='record_list_page'>
            {openRemove && <RemoveDialog text='the record' remove={handleRemoveRecord} close={handleCancelRemoveRecord}/>}
            <div className='search'>
                <SearchBar search={handleSearch} searchWord={searchWord} setSearchWord={setSearchWord}/>
                <div className='actions'>
                    <Button className='action' onClick={handleRefresh}>Refresh</Button>
                    <Link to='/new'><Button className='action'>Create new</Button></Link>
                </div>
            </div>
            <RecordsTable data={tableData} actions={tableActions}/>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReacordsPage);