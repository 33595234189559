import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewsLetterPopup(props) {
    const {
        close,
        confirm
    } = props;

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="confirmDeletion-dialog"
        >
            <DialogTitle id="alert-dialog-slide-title">
            Non hai proposto il Passaparola! Non perdere l'occasione di fidelizzare i tuoi clienti! Clicca su compila ed invia il tuo contatto!
            </DialogTitle>
            <DialogActions>
                <Button className='user_create_dialog_action -close' onClick={close}>
                    Chiudi
                </Button>
                <Button className='user_create_dialog_action -close' onClick={confirm}>
                    Compila
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}