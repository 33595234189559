import {
    PACKAGES
} from '../actions';

const { 
    SAVE_PACKAGES
} = PACKAGES;

const defaultState = {
    packages: [],
    count: 0
};

export default (state = {...defaultState}, action) => {
    const {
        type,
        packages,
        count
    } = action;

    switch (type) {
        
        case SAVE_PACKAGES: {
            return {
                ...state,
                packages,
                count
            }

        }

        default:
            return state;
    }
}