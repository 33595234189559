import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Dropzone from 'react-dropzone'
import classNames from 'classnames';

import { uploadIbans } from './../../../service';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadIbans(props) {
    const [file, setFile] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [isFailedUpload ,setIsFailedUpload] = React.useState(false);
    const [isFileLoaded, setIsFileLoaded] = React.useState(false);

    const {
        close,
        types = [],
        id
    } = props;

    const upload = () => {
        setIsFileLoaded(true);
        const data = file;

        const currentFile = new FormData();

        currentFile.append('file', data, data.name);

        uploadIbans(currentFile).then(resp => {
            const data = resp.data;
            let error = '';

            console.log('DAAATAA', data)

            error += 'Success created: '+ data.successCreated;
            error += ', Amount: ' + data.amount;
            error += ', Wrong fields: ' + data.lengthWrong;
            error += ', Amount error: ' + data.amountError;

            setErrorMessage(error);
            setIsFileLoaded(false);

        }).catch(err => {
            setIsFailedUpload(true);
        });
    }

    const onDrop = (acceptedFiles, rejectedFiles) => {
        const file = acceptedFiles[0];

        if(file && file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv')
            setFile(file);
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="addAddresses-dialog"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Upload IBANS</span>
            </DialogTitle>
            <DialogContent>
                <div className={isFileLoaded ? 'upload_addresses__disable' : 'upload_addresses'}>
                    <Dropzone onDrop={onDrop}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <div
                            {...getRootProps()}
                            className={classNames('dropzone', {'dropzone --isActive': isDragActive})}
                            >
                                <div className='upload_file'>
                                    <input {...getInputProps()} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv'/>
                                    {
                                        isDragActive ?
                                            <div className='drop_section'>
                                                drop
                                            </div>
                                        : !file ?
                                            <div className='try_drop_section'>
                                                <div className='try_drop_section__info'>
                                                <img src='/assets/images/icons/uploadIcon.svg' />
                                                <div>Try dropping some files here, or <span className="try_drop_section--accent">click here</span>to select files to upload.</div>
                                                </div>
                                                </div>
                                            : 

                                            <div className='try_drop_section__success'>
                                            <img src='/assets/images/icons/successIcon.svg' />
                                            <div className="fileName">{file.name}</div>
                                            {errorMessage != null && <div className="infoMessage">{errorMessage}</div>}
                                            </div>
                                    }

                                    {isFailedUpload &&
                                        <div className='try_drop_section__error'>
                                            <img src='/assets/images/icons/failIcon.svg' />
                                            <div>Error. Files not uploaded</div>
                                            <div className="try_drop_section__tryAgainBtn">Try again</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                        }}
                    </Dropzone>
                </div>
            </DialogContent>
            <DialogActions>

            <Button className={file && !isFileLoaded  ? 'user_create_dialog_action -close' : 'user_create_dialog_action -disable'} onClick={upload}>
                Upload
            </Button>
            <Button className='user_create_dialog_action -close' onClick={close}>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}