import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import './index.scss';

import * as generator from 'generate-password';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserDialog(props) {
    const [name, setName] = React.useState('');
    const [login, setLogin] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [role, setRole] = React.useState('');
    const [userParsed, setUserParsed] = React.useState(false);
    const [send_email, setSendEmail] = React.useState(false);

    const [level, setLevel] = React.useState('');

    const {
        open,
        close,
        createUser,
        updateUser,
        userToUpdate
    } = props;

    if(!userParsed && userToUpdate) {
        const {
            name,
            login,
            role,
            level,
            send_email
        } = userToUpdate;

        setName(name);
        setLogin(login);
        setRole(role);
        setLevel(level)
        setUserParsed(true);
        setSendEmail(send_email);
    }

    const handleClickCreate = () => {
        const payload = {
            name,
            login,
            password,
            role,
            level,
            send_email
        };

        createUser(payload);
    };

    const handleClickUpdate = () => {
        const payload = {
            name,
            login,
            password,
            role,
            level,
            send_email
        };

        updateUser(userToUpdate.id, payload);
    };

    const handleClose = () => {
        close();
    };

    const generatePassword = () => {
        setPassword(generator.generate({
            length: 14,
            numbers: true
        }))
    }

    return (
        <div>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="createUser-dialog"
        >
            <DialogTitle id="alert-dialog-slide-title">Create a new user</DialogTitle>
            <DialogContent>
                <div className='user_dialog'>
                    <div className='fields -name'>
                        <TextField
                            className='field'
                            label='Full name'
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <TextField
                            className='field -regular_case'
                            label='Login'
                            value={login}
                            onChange={(event) => setLogin(event.target.value)}
                        />
                    </div>
                    <div className='fields -password'>
                        <TextField
                            className='field -regular_case'
                            label='Password'
                            type='text'
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        <Tooltip title='Generate password'>
                            <IconButton className='action' onClick={generatePassword}>
                                <AllInclusiveIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className='fields -role'>
                    <Select
                        label="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={role}
                        displayEmpty
                        className='field -select'
                        onChange={(event) => setRole(event.target.value)}
                        >
                        <MenuItem value='' disabled>
                            Role
                        </MenuItem>
                        <MenuItem value='admin'>Admin</MenuItem>
                        <MenuItem value='user'>User</MenuItem>
                    </Select>

                    </div>

                    <div className='fields -role'>
                    <Select
                        label="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={level}
                        displayEmpty
                        className='field -select'
                        onChange={(event) => setLevel(event.target.value)}
                        >
                        <MenuItem value='' disabled>
                            Level
                        </MenuItem>
                        {/* <MenuItem value='userWithFE'>User with FE</MenuItem> */}
                        <MenuItem value='base'>Base</MenuItem>
                        <MenuItem value='junior1'>Junior 1</MenuItem>
                        <MenuItem value='junior2'>Junior 2</MenuItem>
                        <MenuItem value='intermedio'>Intermedio</MenuItem>
                        <MenuItem value='esperto'>Esperto</MenuItem>
                        <MenuItem value='superAgent'>Super Agent</MenuItem>
                    </Select>
                    </div>

                    <div className='fields -role'>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={send_email} 
                                    onChange={(e,v) => setSendEmail(v)} 
                                    name="send_email" 
                                />
                            }
                                label="Invio della FE"
                        />

                    </div>
                </div>
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -close' onClick={handleClose}>
                Close
            </Button>
            { userToUpdate && userToUpdate.id 
                ? <Button className='user_create_dialog_action -create' onClick={handleClickUpdate}>
                    Update
                </Button>
                : <Button className='user_create_dialog_action -create' onClick={handleClickCreate}>
                    Create
                </Button>
            }
            
            </DialogActions>
        </Dialog>
        </div>
    );
}