import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import { 
    getDomains,
    createDomain,
    removeDomain
} from '../../../service';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageDomains(props) {
    const {
        close,
    } = props;

    const [domains, setDomains] = React.useState([]);
    const [domainsLoaded, setDomainsLoaded] = React.useState(false);
    const [domain, setDomain] = React.useState('');

    useEffect(() => {
        if(domainsLoaded)
            return

        getDomains().then(resp => {
            const { ok, domains } = resp.data;

            setDomains(domains);
        })

        setDomainsLoaded(true);
    })

    const create = () => {
        createDomain({ name : domain }).then(resp => {
            const { 
                ok,
                domain
            } = resp.data;

            if(!ok)
                console.log("error");

            setDomains([...domains, domain]);
        })
    }

    const removeById = (id) => {
        removeDomain(id).then(resp => {
            const { 
                ok,
            } = resp.data;

            if(!ok)
                console.log("Can't remove");
        })

        setDomains(domains.filter(dom => dom.id != id));
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="manageDomains-dialog"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Add Domains</span>
            </DialogTitle>
            <DialogContent>
                <div className='manage_domains_popup'>
                    <div className='add_domain'>
                        <TextField
                            className='field -regular_case'
                            label='Domain'
                            value={domain}
                            variant="outlined" 
                            onChange={(event) => setDomain(event.target.value)}
                        />
                        <div className='action' onClick={create}>+</div>
                    </div>
                    <div className='domain_list'>
                        <div className='head_domain'>
                            <div className='label'>Name</div>
                            <div className='label'>Actions</div>
                        </div>
                        <div className='list'>
                            {domains.map(domain => {
                                return <div className='domain'>
                                    <div className='name'>{domain.name}</div>
                                    <div className='actions'>
                                        <Tooltip title='Remove'>
                                            <IconButton className='action' onClick={() => { removeById(domain.id) }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            })}
                            
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -close' onClick={close}>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}