import axios from 'axios';
import store from './../store';
import { login as loginT } from './../actions/auth';


axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    config.headers = {
        'content-type': 'application/json',
        'x-accesstoken': (token) ? token : ''
    }

    return config;
});

axios.interceptors.response.use(undefined, err => {
    console.log(err, 'errrr');

    if(err.response.status === 401 && window.localStorage.getItem('token')) {
        window.localStorage.clear();
        window.location.reload();
    }

    throw err;
});

const baseURL       = (process.env.NODE_ENV === 'production') ? '/' : 'http://localhost:8080/';
const api           = baseURL + 'api/v1/';
const usersAPI      = api + 'users/';
const recordsAPI    = api + 'records/';
const scriptsAPI    = api + 'scripts/';
const emailsAPI    = api + 'emails/';
const templatesAPI  = api + 'templates/';
const customfieldsAPI = api + 'customs/';
const zohoAPI = api + 'zoho/';
const verificationAPI = api + 'verification/';
const subscriptionAPI = api + 'subscription/';
const manageFieldAPI  = api + 'manage-fields/';
const packageAPI      = api + 'packages/';
const domainsAPI      = api + 'domains/';
const addressesAPI    = api + 'addresses/';
const ibansAPI        = api + 'ibans/';
const mailingAPI      = api + 'mailing/';
const rulesgAPI      = api + 'rules/';
const newsletterScriptsAPI = api + 'newsletter/scripts';

export const getAllZohoProducts = () => axios.get(zohoAPI + 'products/');
export const getAllZohoUsers = () => axios.get(zohoAPI + 'users/');


export const pdfsLocation  = baseURL + 'pdfs/';

// USERS API
export const createUser     = (payload) => axios.post(usersAPI, payload);
export const getUser        = (id) => axios.get(usersAPI + id);
export const getAllUsers    = () => axios.get(usersAPI);
export const searchUsers    = (payload = {}) => { 

    const {
        limit,
        page,
        searchWord,
        columnName,
        order
    } = payload;

    let query = '?';

    if(limit)
        query += '&limit=' + limit;
    
    if(page)
        query += '&page=' + page;

    if(searchWord)
        query += '&searchWord=' + searchWord;

    if(columnName)
        query += '&columnName=' + columnName;

    if(order)
        query += '&order=' + order;

    return axios.get(usersAPI + '/search/' + query);
 }

export const updateUser     = (id, payload) => axios.put(usersAPI + id, payload);
export const removeUser     = (id) => axios.delete(usersAPI + id);
export const login          = (payload) => axios.post(usersAPI + 'auth', payload);
export const getQrCode      = () => axios.get(usersAPI + 'pair');
export const loadAuthCode   = (payload) => axios.post(usersAPI + 'verify', payload); 
export const checkAuth      = () => axios.get(usersAPI + 'check');
export const loginVerifyEmail   = (payload) => axios.post(usersAPI + 'verify/email', payload);
export const getLogs            = (id) => axios.get(usersAPI + id + '/logs');
export const resetGoogleAuth    = (id) => axios.get(usersAPI + id + '/verify/reset');


// RECORDS API
export const searchRecords = (payload = {}) => {
    const {
        limit,
        page,
        searchWord,
        columnName,
        order
    } = payload;

    let query = '?';

    if(limit)
        query += '&limit=' + limit;
    
    if(page)
        query += '&page=' + page;

    if(searchWord)
        query += '&searchWord=' + searchWord;

    if(columnName)
        query += '&columnName=' + columnName;

    if(order)
        query += '&order=' + order;

    return axios.get(recordsAPI + query).then(resp => {
        const { ok, records } = resp.data;

        if(!ok)
            return resp;

        const newRecords = records.map(record => {
            const parsedData = JSON.parse(record.data);

            record.data = parsedData;

            return record;
        });

        resp.data.records = newRecords;

        return resp;
    });
}

export const createRecord = (payload) => axios.post(recordsAPI, payload);
export const updateRecord = (id, payload) => axios.put(recordsAPI + id, payload);
export const getRecordById = (id) => axios.get(recordsAPI + id);
export const removeRecord = (id) => axios.delete(recordsAPI + id);
export const syncRecord = (id) => axios.get(recordsAPI + id + '/sync');
export const syncRecordSchema = (id) => axios.get(recordsAPI + id + '/sync/schema');
export const syncAllRecord = (id) => axios.get(recordsAPI + id + '/sync-all');
export const createPdf = (id, payload) => axios.post(recordsAPI + id + '/pdf', payload);
export const getReport = (time) => axios.get(recordsAPI + '/report?'+time);
export const createLead = (payload) => axios.post(recordsAPI + '/lead', payload);
export const sendEmailZoho = (id) => axios.get(recordsAPI + '/' + id + '/email');
export const sendEmailZohoNew = (payload) => axios.post(recordsAPI + 'send/email', payload);
export const sendNamirial= (id) => axios.get(recordsAPI + id + '/namirial');

// SCRIPTS API
export const searchScripts = (payload = {}) => {
    const {
        limit,
        page,
        searchWord,
        columnName,
        order
    } = payload;

    let query = '?';

    if(limit)
        query += '&limit=' + limit;
    
    if(page)
        query += '&page=' + page;

    if(searchWord)
        query += '&searchWord=' + searchWord;

    if(columnName)
        query += '&columnName=' + columnName;

    if(order)
        query += '&order=' + order;

    return axios.get(scriptsAPI + query);
}

export const createScript = (payload) => axios.post(scriptsAPI, payload);
export const getScriptById = (id) => axios.get(scriptsAPI + id);
export const updateScript = (id, payload) => axios.put(scriptsAPI + id, payload);
export const removeScript = (id) => axios.delete(scriptsAPI + id);

// EMAILS
export const getAllEmails = () => axios.get(emailsAPI);
export const createEmail = (payload) => axios.post(emailsAPI, payload);
export const updateEmail = (id, payload) => axios.put(emailsAPI + id, payload);
export const removeEmail = (id) => axios.delete(emailsAPI + id);
export const changeOrder = (payload) => axios.post(emailsAPI + 'order', payload);

// Templates API
export const searchTemplates = (payload = {}) => {
    const {
        limit,
        page,
        searchWord,
        columnName,
        order
    } = payload;

    let query = '?';

    if(limit)
        query += '&limit=' + limit;
    
    if(page)
        query += '&page=' + page;

    if(searchWord)
        query += '&searchWord=' + searchWord;

    if(columnName)
        query += '&columnName=' + columnName;

    if(order)
        query += '&order=' + order;

    return axios.get(templatesAPI + query);
}

export const createTemplate = (payload) => axios.post(templatesAPI, payload);
export const getTemplateById = (id) => axios.get(templatesAPI + id);
export const updateTemplate  = (id, payload) => axios.put(templatesAPI + id, payload);
export const uploadFileTemplate = (id, payload) => axios.post(templatesAPI + id + '/upload', payload);
export const removeTemplate = (id) => axios.delete(templatesAPI + id);


// CUSTOM FIELDS
export const createCustomField = (payload) => axios.post(customfieldsAPI ,payload);
export const getCustomFieldsById = (id) => axios.get(customfieldsAPI + '/' + id);
export const getCustomFields = () => axios.get(customfieldsAPI);
export const updateCustomField = (id, payload) => axios.put(customfieldsAPI + '/' + id, payload);
export const removeCustomField = (id) => axios.delete(customfieldsAPI + '/' + id);


// VERIFICATION
export const verifyEmail = (payload) => axios.post(verificationAPI + 'email', payload);

// SUBSCRIPTION
export const subscribeUser = (payload) => axios.post(subscriptionAPI, payload);

// MANAGE FIELDS
export const createManageField = (payload) => axios.post(manageFieldAPI, payload);
export const checkManageField = (payload) => axios.post(manageFieldAPI, payload);
export const updateManageField = (id, payload) => axios.put(manageFieldAPI + id, payload);
export const getManageFields = () => axios.get(manageFieldAPI);


// PACKAGES
export const createPackage = (payload) => axios.post(packageAPI, payload);
export const updatePackage = (id, payload) => axios.put(packageAPI + id, payload);
export const getAllPackages = () => axios.get(packageAPI);
export const removePackage = (id) => axios.delete(packageAPI + id);


// DOMAINS
export const getDomains   = () => axios.get(domainsAPI);
export const createDomain = (payload) => axios.post(domainsAPI, payload);
export const removeDomain = (id) => axios.delete(domainsAPI + id);

// ADDRESSES
export const uploadFile = (payload) => axios.post(addressesAPI + 'upload', payload);
export const verifyAddress = (payload) => axios.post(addressesAPI + 'verify', payload);

// IBANS
export const uploadIbans = (payload) => axios.post(ibansAPI + 'upload', payload);
export const verifyIban = (payload) => axios.post(ibansAPI + 'verify', payload);

// MAIL TEMAPLTES (CHECKBOXES)
export const getAllMailCheckboxes = () => axios.get(mailingAPI + 'template');
export const createMailCheckbox = (payload) => axios.post(mailingAPI + 'template', payload);
export const updateMailCheckbox = (id, payload) => axios.put(mailingAPI + 'template/' + id, payload);
export const removeMailCheckbox = (id) => axios.delete(mailingAPI + 'template/' + id);

export const createQuestion = (payload) => axios.post(rulesgAPI, payload);
export const getQuestion = () => axios.get(rulesgAPI);


// NEWSLETTER SCRIPTS
export const getNewsletterScripts = () => axios.get(newsletterScriptsAPI);
export const createNewsletterScripts = (payload) => axios.post(newsletterScriptsAPI, payload);
export const updateNewsletterScripts = (id, payload) => axios.put(newsletterScriptsAPI + '/' + id, payload);
export const deleteNewsletterScripts = (id) => axios.delete(newsletterScriptsAPI + '/' + id);