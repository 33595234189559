import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import Tooltip from '@material-ui/core/Tooltip';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RemoveDialog(props) {
    const {
        close,
        remove,
        text
    } = props;

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="confirmDeletion-dialog"
        >
            <DialogTitle id="alert-dialog-slide-title">Remove {text}</DialogTitle>
            <DialogContent className="confirmDeletion-dialog__content">
                Are you sure what you want to remove?
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -close' onClick={close}>
                Close
            </Button>
            <Button className='user_create_dialog_action -delete' onClick={remove}>
                Remove
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}