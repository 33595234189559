import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

function SearchBar(props) {
    let [searchWord, setSearchWord] = React.useState(null);

    if(props.searchWord && searchWord === null)
        searchWord = props.searchWord;

    return (
        <div className="search_input--wrapper">
        <label className="customLabel" for="search_input">Search</label>
        <TextField
            className='search_input'
            placeholder="Works for Name, Last Name, Login and Role fields"
            margin="normal"
            id="search_input"
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            value={searchWord}
            onChange={(event) => setSearchWord(event.target.value)}

            onKeyDown={(event) => {
                if(event.keyCode === 13) {
                    props.search(event.target.value)
                }
            }}
        />
        </div>
    )
}


export default SearchBar