import {
    LOGIN,
    LOGOUT
} from './index';


export const login = (payload) => {
    const {
        auth,
        id,
        role,
        level,
        name,
        send_email
    } = payload;
    
    return {
        type: LOGIN,
        auth,
        id,
        role,
        level,
        name,
        send_email
    }
}

export const logout = () => {
    return {
        type: LOGOUT,
        auth: false,
        id: null,
        role: null,
        level: null,
        name: '',
        send_email: false
    }
}