import {
    LOADING_SCRIPTS,
    ERROR_LOAD_SCRIPTS,
    SAVE_SCRIPTS,
    SAVE_SEARCH_WORD_SCRIPTS,
    SET_ORDER_SCRIPTS,
    SET_ORDER_BY_SCRIPTS,
    SET_PAGE_SCRIPTS,
    SET_ROWS_PAGE_SCRIPTS
} from '../actions/scripts';

const defaultState = {
    scripts: [], 
    count: 0,
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'updatedAt',
    searchWord: '',
    loadingRecords: false,
    errorMessage: '',
    errorLoading: false
}

export default (state = defaultState, action) => {
    const {
        type,
        scripts,
        count,
        searchWord,
        page,
        rowsPerPage,
        order,
        orderBy,
        loadingRecords,
        errorMessage,
        errorLoading
    } = action;

    switch (type) {
        case LOADING_SCRIPTS:
            return {
                ...state,
                loadingRecords
            }

        case ERROR_LOAD_SCRIPTS:
            return {
                ...state,
                loadingRecords,
                errorLoading,
                errorMessage
            }

        case SAVE_SCRIPTS:
            return {
                ...state,
                loadingRecords,
                scripts,
                count: count ? count : state.count
            }
        case SAVE_SEARCH_WORD_SCRIPTS:
            return {
                ...state,
                searchWord
            }
        case SET_ORDER_SCRIPTS:
            return {
                ...state,
                order
            }

        case SET_ORDER_BY_SCRIPTS:
            return {
                ...state,
                orderBy
            }

        case SET_PAGE_SCRIPTS:
            return {
                ...state,
                page
            }

        case SET_ROWS_PAGE_SCRIPTS:
            return {
                ...state,
                rowsPerPage
            }

        default:
            return state;
    }
}