import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { createPdf } from '../../../service';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreatePDF(props) {
    const {
        close,
        types = [],
        id
    } = props;

    const create = (id, type) => {
        createPdf(id, { type }).then(resp => {
            const { 
                ok,
                links
            } = resp.data;

            if(!ok)
                console.log("Can't create a PDF file");

            links.map(link => {
                window.open('/pdf/' + link, '_blank_' + link);
            });
        })
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Create PDF</span>
            </DialogTitle>
            <DialogContent>
                <div className='create_pdf_dialog'>
                    <div className='actions'>
                        {types.map(type => {
                            return <Button className='action' onClick={() => create(id, type)}>{type}</Button>
                        })}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -close' onClick={close}>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}