import React, { Component } from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';

import ListCustomField from './list';
import NewCustomField from './new';

function Customs() {
    return (
        <HashRouter basename='/templates/custom'>
            <Switch>
                <Route path='/new' component={NewCustomField}/>
                <Route path='/:id' component={NewCustomField}/>
                <Route path='/' component={ListCustomField}/>
            </Switch>
        </HashRouter>
    )
}

export default Customs