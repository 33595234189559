import {
    LOGIN,
    LOGOUT
} from '../actions';

export default (state = { auth: false, role: null, level: null, name: '' }, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                auth: action.auth,
                id: action.id,
                role: action.role,
                level: action.level,
                name: action.name,
                send_email: action.send_email
            }

        case LOGOUT:
            return {
                ...state,
                auth: false
            }

        default:
            return state;
    }
}