import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Link, Redirect } from 'react-router-dom';

import ScriptsTable from '../../../components/scriptsTable';
import SearchBar from '../../../components/scriptsTable/searchBar';
import RemoveDialog from '../../../components/dialogs/remove';

import { 
    loadingScripts,
    loadingError,
    saveScripts,
    saveSearchWrod,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage
} from '../../../actions/scripts';

import { 
    openNotification
} from '../../../actions/notification';

import { debounce } from '../../../libs/ownScripts';
import * as service from '../../../service';



import './index.scss';

const mapStateToProps = state => ({
    ...state.scripts
})

const mapDispatchToProps = dispatch => ({
    saveScripts: (payload) => {
        return dispatch(saveScripts(payload));
    },
    saveSearchWord: (word) => {
        return dispatch(saveSearchWrod(word));
    },
    setOrder: (order) => {
        return dispatch(setOrder(order));
    },
    setOrderBy: (orderBy) => {
        return dispatch(setOrderBy(orderBy));
    },
    setPage: (page) => {
        return dispatch(setPage(page));
    },
    setRowsPerPage: (rowsPerPage) => {
        return dispatch(setRowsPerPage(rowsPerPage));
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
})


function ScriptsPage(props) {
    const [perPage, setPerPage] = React.useState(10);
    const [openRemove, setOpenRemove] = React.useState(false);
    const [removeScript, setRemoveScript] = React.useState(null);
    const [scriptToUpdate, setScriptToUpdate] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false);

    if(redirect)
        return <Redirect to={'/' + scriptToUpdate}/>

    // Data
    const {
        scripts,
        count,
        searchWord,
        page,
        rowsPerPage,
        order,
        orderBy
    } = props;

    const tableData = {
        page,
        scripts,
        rowsPerPage,
        count,
        order,
        orderBy
    };

    const setOrder = (order) => {
        props.setOrder(order);
    }

    const setOrderBy = (orderBy) => {
        props.setOrderBy(orderBy);
    }

    const setPage = (page) => {
        props.setPage(page);
    }

    const setRowsPerPage = (rowsPerPage) => {
        setPerPage(rowsPerPage);
        props.setRowsPerPage(rowsPerPage);
    }

    const setSearchWord = (searchWord) => {
        props.saveSearchWord(searchWord);
    }

    const getRequestParams = (addParams) => {
        const payload = {
            searchWord,
            columnName: orderBy,
            order,
            limit: rowsPerPage,
            page
        }

        return { ...payload, ...addParams };
    }

    const handleRemoveScript = () => {
        const scriptId = removeScript.id;

        service.removeScript(scriptId).then(resp => {
            const {
                ok
            } = resp.data;

            if(!ok)
                return false;

            const scriptLeft = scripts.filter(script => script.id !== scriptId);

            props.saveScripts({
                scripts: scriptLeft,
                count: scriptLeft.length
            })

            handleCancelRemoveScript()
            props.openNotification({ notificationText: 'Script has been removed', notificationType: 'success' });
            
        })
    }

    const handleCancelRemoveScript = () => {
        setOpenRemove(false);
        setRemoveScript(null);
    }

    const askRemove = (script) => {
        setOpenRemove(true);
        setRemoveScript(script);
    }

    const handleChangePage = (event, newPage) => {
        const leng = scripts.length;
  
        if(leng <= (rowsPerPage * page) + rowsPerPage) {
            const payload = getRequestParams({ page: newPage });
            
            service.searchScripts(payload).then(resp => {
                const {
                    ok,
                    scripts: respScripts,
                    count
                } = resp.data;
    
                const newScripts = [...scripts, ...respScripts];
    
                props.saveScripts({ scripts: newScripts, count });
                props.setPage(newPage);
            })
        }else {
            props.setPage(newPage);
        }
    };

    const handleRequestSort = (event, prop) => {
        const isAsc = orderBy === prop && order === 'asc';
        const sort = isAsc ? 'desc' : 'asc'
        setOrder(sort);
        setOrderBy(prop);

        const payload = getRequestParams({ order: sort, orderBy: prop });

        service.searchScripts(payload).then(resp => {
            const {
                ok,
                scripts,
                count
            } = resp.data;

            props.saveScripts({ scripts, count });
            props.setPage(0);
        });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (word) => {
        props.saveSearchWord(word);

        const payload = getRequestParams({ searchWord: word });

        service.searchScripts(payload).then(resp => {
            const {
                ok,
                scripts,
                count
            } = resp.data;

            props.saveScripts({ scripts, count });
            props.setPage(0);
        });

    };

    const handleRefresh = () => {
        const payload = getRequestParams({});

        service.searchScripts(payload).then(resp => {
            const {
                ok,
                scripts,
                count
            } = resp.data;

            props.saveScripts({ scripts, count });
            props.setPage(0);
            props.openNotification({ notificationText: ' Script table has been refreshed', notificationType: 'success' });
        });

    }

    const editScript = (id) => {
        setScriptToUpdate(id);
        setRedirect(true);
    }

    const tableActions = {
        handleRequestSort,
        setPage,
        setRowsPerPage,
        setSearchWord,
        handleChangePage,
        handleChangeRowsPerPage,
        askRemove,
        editScript
    };

    return (
        <div className='scripts_list_page'>
            {openRemove && <RemoveDialog text='the script' remove={handleRemoveScript} close={handleCancelRemoveScript}/>}
            <div className='search'>
                <SearchBar search={handleSearch} searchWord={searchWord} setSearchWord={setSearchWord}/>
                <div className='actions'>
                    <Button className='action' onClick={handleRefresh}>Refresh</Button>
                    <Link to='/new'><Button className='action'>Create new</Button></Link>
                </div>
            </div>
            <ScriptsTable data={tableData} actions={tableActions}/>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScriptsPage);