import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InfoDialog(props) {
    const {
        close,
        text
    } = props;

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="confirmDeletion-dialog"
        >
            <DialogTitle id="alert-dialog-slide-title">{text}</DialogTitle>
            <DialogActions>
            <Button className='user_create_dialog_action -close' onClick={close}>
                Chiudi
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}