import React, { Component } from 'react';
import { Button, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Chip from '../chip';

// styles
import './index.scss';

import store from '../../store';

const mapStateToProps = state => ({
    counts: {
        records: state.records.count,
        scripts: state.scripts.count,
        templates: state.templates.count,
        users: state.users.count,
        packages: state.packages.count
    }
});

const mapDispatchToProps = dispatch => ({

})

class Switcher extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { path } = this.props;
        const { counts } = this.props;

        const userRole = store.getState().auth.role;
        
        if(path === '/')
            path = '/records';

        return(
            <div className='switcher'>
                {buttons.map((button, index) => {
                    const regexp = new RegExp(`(/${button.link})`, 'i');
                    
                    if(button.permision === 'any' || button.permision === userRole)
                        return (
                            <Link to={'/' + button.link} key={index}>
                                <Button
                                    className={regexp.test(path) ? 'switcher_button_active': 'switcher_button'}>
                                        {button.name}
                                    <Chip count={counts[button.id]}/>
                                </Button>
                            </Link>
                        )
                        
                        
                })}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Switcher);

const buttons = [
    {
        id: 'records',
        name: 'Records',
        link: 'records',
        permision: 'any'
    },
    {
        id: 'scripts',
        name: 'Scripts',
        link: 'scripts',
        permision: 'admin'
    },
    {
        id: 'templates',
        name: 'Templates',
        link: 'templates',
        permision: 'admin'
    },
    {
        id: 'users',
        name: 'Users',
        link: 'users',
        permision: 'admin'
    },
    {
        id: 'packages',
        name: 'Packages',
        link: 'packages',
        permision: 'admin'
    }
];

