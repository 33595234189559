import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

import LoginPage from './pages/login';
import Content from './pages/index';

import { checkAuth, loginVerifyEmail } from './service';
import { login } from './actions/auth';

import './App.scss';

import store from './store';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: false,
      checked: false
    }
  }

  componentDidMount() {
    if(!localStorage.getItem('token'))
      return this.setState({ auth: false, checked: true });

    checkAuth().then(resp => {
      const { 
        ok,
        id,
        role = null, 
        level = null,
        name = null, 
        send_email = false,
        verified,
        verified_email,
        activated_g
      } = resp.data;

      if(!verified || !activated_g || !verified_email) {
        return this.setState({ auth: false, checked: true });
      }
      
      store.dispatch(login({
        auth: ok,
        id,
        role,
        level,
        name,
        send_email
      }));

      this.setState({ auth: ok, checked: true });
        
    }).catch(err => {
      return this.setState({ auth: false, checked: true });
    })

    setInterval(() => {
      checkAuth().then(resp => {
        // const { ok } = resp.data;
  
        // this.setState({ auth: ok, checked: true });
      }).catch(err => {
        // return this.setState({ auth: false, checked: true });
      })
    }, 90000);
  }

  render() {
    const { auth, checked } = this.state;

    return <HashRouter>
        <Switch>
            <Route render={() => (
                checked ? 
                  auth ? (  
                          <Route component={ Content }/>
                        ) : (
                          <Route component={ LoginPage }/>
                        )
                : ''
                )
            }/>
        </Switch>
      </HashRouter>
  };

}