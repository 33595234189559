import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import './index.scss';

function UserCard(props) {
    const {
        name,
        login,
        role,
        id,
        updatedAt
    } = props.data;

    const {
        update,
        remove
    } = props;

    return (
        <div className='user_card'>
            <div className='header'></div>
            <div className='info'>
                <div className='name'>
                    {name} ({id})
                </div>
                <div className='priv_info'>
                    <div className='login'>
                        {login}
                    </div>
                    <div className='role'>
                        {role}
                    </div>
                </div>
                <div className='last_login'>{
                    moment(updatedAt).format('DD/MM/YYYY hh:mm:ss a')
                }</div>
            </div>
            <div className='actions'>
                <Button className='action' onClick={() => update(props.data)}>Update</Button>
                <Button className='action -remove' onClick={remove}>Remove</Button>
            </div>
        </div>
    )
}


export default UserCard