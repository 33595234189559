import React, { Component, useEffect, useState, useSelector } from 'react';
import { Button, Icon, Tooltip } from '@material-ui/core';
import { Link, Route, HashRouter, Switch } from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import { Menu as MenuMui, MenuItem } from '@material-ui/core';

// styles
import './index.scss';

const mapStateToProps = state => ({
    level: state.auth.level,
    users: state.users.users,
    send_email: state.auth.send_email,
    name: state.auth.name,
});

function Menu(props) {

    // menu hamburger
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [level, setLevel]= useState(props.level);
    const [badges, setBadges] = useState([])
    const [isBadgesSetted, setIsBadgesSetted] = useState(false);
    const [currentBadges,setCurrentBadges]= useState([
        {
            title: 'userWithFE', 
            img: '/assets/images/badges/userWithFE-badge.svg', 
            blured: false,
        }

    ]);

    useEffect(() => {
    if(badges.length && level) {
        let badgeIndex = badges.findIndex(x => x.title === level);
        if(badgeIndex === badges.length - 1) {
            setCurrentBadges([badges[badgeIndex], ...currentBadges]);
        } else {
            setCurrentBadges([...badges.slice(badgeIndex, badgeIndex + 2), ...currentBadges])

            let updatedCurrentBadges = [...badges.slice(badgeIndex, badgeIndex + 2), ...currentBadges]; 
                updatedCurrentBadges[1].blured = true
                setCurrentBadges(updatedCurrentBadges);
        }
    }
    }, [badges])

    useEffect(() => {
        if(isBadgesSetted) return;
        if(props.users.length) {
            let badges = [
                {title: 'base', img: '/assets/images/badges/base-badge.svg', blured: false},
                {title: 'junior1', img: '/assets/images/badges/junior1-badge.svg', blured: false},
                {title: 'junior2', img: '/assets/images/badges/junior2-badge.svg', blured: false},
                {title: 'intermedio', img: '/assets/images/badges/intermedio-badge.svg', blured: false},
                {title: 'esperto', img: '/assets/images/badges/esperto-badge.svg', blured: false},
                {title: 'superAgent', img: '/assets/images/badges/superAgent-badge.svg', blured: false},
            ]
            
            // add number of users on each level
            const updatedBadgesInfo = badges.map(x => {
               return {...x, number: props.users.filter(y => y.level === x.title).length}
            })
            let updatedCurrentBadges = [...currentBadges]; 
            updatedCurrentBadges[0].number = props.users.filter(y => y.send_email === true).length; 
            updatedCurrentBadges[0].blured = props.send_email ? false : true;
            setCurrentBadges(updatedCurrentBadges);

            setBadges(updatedBadgesInfo)
            setIsBadgesSetted(true);
        }
    }, [props.users])

    // menu hamburger
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

    const logout = () => {
        localStorage.clear();
        window.location.href = '';
    }

    const { 
        openGenerateReport,
        openDomainManager,
        openUploadList,
        openUploadIbans,
        openMailCheckboxes,
        openQuestionManager,
        openEmailsManager,
        role 
    } = props;

    return(
        <div className='menu'>
           <div className='logo'>
               <img src='/assets/images/logo/selectra_logo.svg'/>
           </div>
           <div className='app_name'>|  OPP Italia</div>
           <div className='userInfoWrapper'>
               <div className='userInfo__name'>{`Ciao, ${props.name}!`}</div>
           {currentBadges.length >= 2 && 
                <div className='badgesWrapper'>
                    {currentBadges.map((x, index)=> (
                        <Badge badge={x} index={index} currentBadges={currentBadges}/>
                        ))}
                </div>
            }
            </div>
           <div className='actions'>
            {role === 'admin' && <Button onClick={openDomainManager}>Domains</Button> }
            {role === 'admin' && <Button onClick={openEmailsManager}>Emails</Button> }
            {role === 'admin' && <Button onClick={openGenerateReport}>Reports</Button> }
            <Button onClick={() => {
                window.open('https://ocr-energy.web.app/app', '_ocr');
            }}>OCR</Button>
            <Button onClick={logout}>Logout</Button>
            {role === 'admin' &&
            <>
                <IconButton
                    onClick={handleClick}
                    sx={{ ml: "30px"}}
                    className="menuIconWrapper"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {open 
                        ? <img className="menuIcon" src="/assets/images/icons/menuCloseIcon.svg" alt="menu close icon"/> 
                        : <img className="menuIcon" src="/assets/images/icons/menuIcon.svg" alt="menu icon"/>
                    }
                </IconButton>

                <MenuMui
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    className="burgerMenuWrapper"
                >
                    {role === 'admin' && <MenuItem onClick={openMailCheckboxes}>M-Checkboxes</MenuItem>}
                    {role === 'admin' && <MenuItem onClick={openQuestionManager}>questionari</MenuItem>}
                    {role === 'admin' && <MenuItem onClick={openUploadIbans}>IBAN</MenuItem>}
                    {role === 'admin' && <MenuItem onClick={openUploadList}>Addresses</MenuItem>}
                </MenuMui>
            </>
            }

           </div>
        </div>
    )
}



export default connect(
    mapStateToProps,
    null
)(Menu);


export const Badge = (props) => {
    const badge = props.badge;
    const badges = props.currentBadges;
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
      };
    
      const handleTooltipOpen = () => {
        setOpen(true);
      };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div  className='badgeWrapper'>
                {badges.length === 3 && props.index === 1 && <div style={{color: '#A3A3A2', fontSize: "12px", marginRight: '12px', fontWeight: '500', marginTop: "3px"}}>Next Level <img style={{width: "10px", marginLeft: '3px', marginTop: "0px"}} src="/assets/images/icons/rightArrow.svg"/></div>}
                <div className='badge'>
                    {props.index > 0 
                    ?
                    <Tooltip
                        PopperProps={{
                        disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={badge.title === 'userWithFE' ? `Ci sei quasi! Raggiungi gli altri ${badge.number} operatori che possono inviare automaticamente la FE` : `Ci sei quasi! Raggiungi gli altri ${badge.number} operatori che hanno raggiunto questo livello.`}
                        placement='bottom-start'
                    >
                        <div>
                            <div className={badge.blured && !open ? 'blurFilter' : ''}></div>
                            <img src={badge.img} alt={`your level ${badge.title}`}  onClick={handleTooltipOpen}/>
                        </div>
                    </Tooltip>
                    : <img src={badge.img} alt={`your level ${badge.title}`}  onClick={handleTooltipOpen}/>
                    }
                </div>
            </div>
        </ClickAwayListener>
    )
}

// {title: 'admin', img: '/assets/images/badges/admin-badge.svg'},
// {title: 'user', img: '/assets/images/badges/user-badge.svg'},
// {title: 'userWithFE', img: '/assets/images/badges/userWithFE-badge.svg'},