export const LOADING_SCRIPTS = "LOADING_SCRIPTS";
export const ERROR_LOAD_SCRIPTS = "ERROR_LOAD_SCRIPTS";
export const SAVE_SCRIPTS = "SAVE_SCRIPTS";
export const SAVE_SEARCH_WORD_SCRIPTS = "SAVE_SEARCH_WORD_SCRIPTS";
export const SET_ORDER_SCRIPTS = "SET_ORDER_SCRIPTS";
export const SET_ORDER_BY_SCRIPTS = "SET_ORDER_BY_SCRIPTS";
export const SET_PAGE_SCRIPTS = "SET_PAGE_SCRIPTS";
export const SET_ROWS_PAGE_SCRIPTS = "SET_ROWS_PAGE_SCRIPTS";


export const loadingRecords = () => {
    return {
        type: LOADING_SCRIPTS,
        loadingRecords: true
    }
}

export const loadingError = () => {
    return {
        type: ERROR_LOAD_SCRIPTS,
        loadingRecords: false
    }
}

export const saveScripts = (payload) => {
    const { scripts, count } = payload;
    
    return {
        type: SAVE_SCRIPTS,
        loadingRecords: false,
        scripts,
        count
    }
}

export const saveSearchWrod = (word) => {
    return {
        type: SAVE_SEARCH_WORD_SCRIPTS,
        searchWord: word
    }
}

export const setOrder = (order) => {
    return {
        type: SET_ORDER_SCRIPTS,
        order
    }
}
export const setOrderBy = (orderBy) => {
    return {
        type: SET_ORDER_BY_SCRIPTS,
        orderBy
    }
}
export const setPage = (page) => {
    return {
        type: SET_PAGE_SCRIPTS,
        page
    }
}
export const setRowsPerPage = (rows) => {
    return {
        type: SET_ROWS_PAGE_SCRIPTS,
        rowsPerPage: rows
    }
}