import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import customs from './customs';
import records from './records';
import scripts from './scripts';
import templates from './templates';
import notifications from './notification';
import loader from './loader';
import managedFields from './managedFields';
import packages from './packages';

export default combineReducers({
    auth,
    users,
    customs,
    records,
    scripts,
    templates,
    notifications,
    loader,
    managedFields,
    packages
});