import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DupPopup(props) {
    const {
        close,
        syncAction
    } = props;

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Duplicate Record</span>
            </DialogTitle>
            <DialogContent>
                Sei sicuro di voler duplicare la scheda? La scheda può essere duplicata solo se
                l’intestatario è lo stesso. In altri casi, devi creare un nuovo contatto su Zoho con la
                leadsource amico
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -close' onClick={syncAction}>
                Si
            </Button>
            <Button className='user_create_dialog_action -close' onClick={close}>
                No
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}