import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Redirect } from 'react-router-dom';
import store from '../../../store';

import PreviewScript from '../../../components/dialogs/previewScript';

import {
    saveScripts,
} from '../../../actions/scripts';

import './index.scss';

import { 
    openNotification
} from '../../../actions/notification';

import * as service from '../../../service';

const mapStateToProps = state => ({
    ...state.scripts
})

const mapDispatchToProps = dispatch => ({
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
})

function CreateScript(props) {
    const [client_type, setClientType] = React.useState('');
    const [tipo_di_utenza, setTipoDiUtenza] = React.useState('');
    const [name, setName] = React.useState('');
    const [template, setTemplate] = React.useState('');
    const [script, setScript] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [pdfFile, setPdfFile] = React.useState(null);
    const [templates, setTemplates] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [preview, setPreview] = React.useState(false);

    const id = props.match.params.id;

    useEffect(() => {
        const fetchData = async () => {
            const payload = {
                // searchWord: name,
                limit: 500
            };

            service.searchTemplates(payload).then(resp => {
                const {
                    ok,
                    templates,
                    count
                } = resp.data;

                if(!ok) 
                    return false;

                setTemplates(templates);
            })
        };
        
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            service.getScriptById(id).then(resp => {
                const {
                    ok,
                    script
                } = resp.data;

                const {
                    name,
                    Template,
                    tipo_di_utenza,
                    client_type,
                    script: textScript
                } = script

                if(!ok) 
                    return false;

                
                setName(name);
                setTemplate(Template);
                setScript(textScript);
                setClientType(client_type);
                setTipoDiUtenza(tipo_di_utenza);
            })
        };
        
        if(id)
            fetchData();
    }, []);

    // service.searchTemplates({ limit: 10 }).then(resp => {
    //     const {
    //         ok,
    //         templates
    //     } = resp.data;

    //     setTemplates(templates);
    // })

    if(redirect)
        return <Redirect to='/'/>

    const handleSearch = (name) => {
        // const payload = {
        //     searchWord: name,
        //     limit: 10
        // };

        // setLoading(true);


        // service.searchTemplates(payload).then(resp => {
        //     const {
        //         ok,
        //         templates
        //     } = resp.data;

        //     setTemplates(templates);
        //     setLoading(false);
        // })
    }
    
    

    const handleCreate = () => {
        const payload = {
            name,
            template_fk: template.id,
            tipo_di_utenza,
            client_type,
            script
        }

        const scripts = store.getState().scripts.scripts;

        service.createScript(payload).then(resp => {
            const {
                ok,
                scriptId
            } = resp.data;

            if(!ok)
                return false;

            const newScript = {
                id: scriptId,
                name,
                Template: template,
                script,
                tipo_di_utenza,
                client_type,
                updatedAt: +new Date()
            }

            scripts.unshift(newScript);
            store.dispatch(saveScripts({ scripts, count: scripts.length }));
            setRedirect(true);

            props.openNotification({ notificationText: 'Script has been created', notificationType: 'success' });
        })
    }

    const handleUpdate = () => {
        const payload = {
            name,
            tipo_di_utenza,
            client_type,
            template_fk: template.id,
            script
        }

        const scripts = store.getState().scripts.scripts;

        service.updateScript(id, payload).then(resp => {
            const {
                ok,
                scriptId
            } = resp.data;

            if(!ok)
                return false;

            const updatedScript = {
                ...payload,
                id,
                Template: template,
                updatedAt: + new Date()
            }

            const updatedScripts = scripts.map(script => {
                if(id != script.id)
                    return script;

                return updatedScript
            })

            store.dispatch(saveScripts({ scripts: updatedScripts }));
            setRedirect(true);

            props.openNotification({ notificationText: 'Script has been updated', notificationType: 'success' });
        })
    }

    return (
        <div className='scripts_create_page'>
            {preview && <PreviewScript textMessage={script} close={() => setPreview(false)}/>}
            <div className='fields_section'>
                <div className='section'>
                    <div className='head_text'>Operation Edition page</div>
                    <div className='fields -client_energia'>
                        <FormControl variant="outlined" className='field -select'>
                            <InputLabel id="demo-simple-select-outlined-label">Type of client</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={client_type}
                                helperText='client_type'
                                name='client_type'
                                onChange={(event, val) => setClientType(event.target.value)}
                                label="Type of client"
                                >
                                <MenuItem value='domestico'>Domestico</MenuItem>
                                <MenuItem value='business'>Business</MenuItem>
                            </Select>
                            <FormHelperText>client_type</FormHelperText>
                        </FormControl>
                        <FormControl variant="outlined" className='field -select'>
                            <InputLabel id="demo-simple-select-outlined-label">Tipo di Utenza</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={tipo_di_utenza}
                                helperText='tipo_di_utenza'
                                name='tipo_di_utenza'
                                onChange={(event, val) => setTipoDiUtenza(event.target.value)}
                                label="Tipo di Utenza"
                                >
                                <MenuItem value='gas'>GAS</MenuItem>
                                <MenuItem value='luce'>LUCE</MenuItem>
                            </Select>
                            <FormHelperText>tipo_di_utenza</FormHelperText>
                        </FormControl>
                    </div>


                    { client_type && tipo_di_utenza && <div className='fields -multiple'>
                        <TextField 
                            className='field' 
                            onChange={event => setName(event.target.value)} 
                            required 
                            label="Operation Name" 
                            value={name} />

                        <Autocomplete
                            className='field' 
                            id="standard-select-currency"
                            select
                            options={templates}
                            loading={loading}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                            value={template}
                            onChange={(event, val) => setTemplate(val)}
                            // onInputChange={event => handleSearch(event.target.value)}
                            renderInput={(params) => <TextField {...params} label="PDF Template" variant="outlined" />}
                            >
                        </Autocomplete>
                        </div>
                    }

                    { client_type && tipo_di_utenza && <div className='fields -column'>
                        <TextField
                            className='field'
                            id="outlined-multiline-static"
                            label="HTML box for script definition"
                            multiline
                            rows={8}
                            value={script}
                            onChange={event => setScript(event.target.value)}
                            variant="outlined"/>
                        
                        <Button className='action' onClick={() => setPreview(true)}>
                            Preview
                        </Button>
                    </div>
                    }

                    <div className='preview'>
                        {preview}
                    </div>
                </div>
            </div>
            { client_type && tipo_di_utenza && <div className='actions'>
                { !id 
                    ? 
                    <Button onClick={handleCreate} className='action'>
                        Add
                    </Button>
                    :
                    <Button onClick={handleUpdate} className='action'>
                        Update
                    </Button>
                }
            </div>
            }   
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateScript);