import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';

import './index.scss';

import * as service from '../../../service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PackageDialog(props) {
    const [packageName, setPackageName] = React.useState('');
    const [zohoId, setZohoId] = React.useState('');
    const [departmentCode, setDepartmentCode] = React.useState('');
    const [department, setDepartment] = React.useState('');
    const [clientType, setClientType] = React.useState('');
    const [productType, setProductType] = React.useState('');
    const [vendor, setVendor] = React.useState('');
    const [product, setProduct] = React.useState('');
    const [productName, setProductName] = React.useState('');
    const [productId, setProductId] = React.useState('');
    const [products, setProducts] = React.useState([]);
    const [selectedProducts, setSelectedProducts] = React.useState([])
    const [loadProductSend, setLoadProductSend] = React.useState(false);
    const [parsedFields, setParsedFields] = React.useState(false);

    const {
        open,
        close,
        createPackage,
        updatePackage,
        packageToUpdate
    } = props;

    useEffect(() => {
        if(loadProductSend)
            return;

        setLoadProductSend(true);

        service.getAllZohoProducts().then(resp => {
            const { ok, products } = resp.data;

            const onlyActive = products.filter(product => product.product_active);
        
            if(ok)
                setProducts(onlyActive);
        }).catch(err => {
            setLoadProductSend(false);
            console.log(err);
        });
    })

    useEffect(() => {
        if(parsedFields)
            return;

        if(packageToUpdate) {
            const {
                package_name,
                zoho_Id,
                department_code,
                department,
                client_type,
                product_type,
                vendor,
                product_name,
                product_id,
                products = []
            } = packageToUpdate;
    
            setPackageName(package_name);
            setZohoId(zoho_Id);
            setDepartmentCode(department_code);
            setDepartment(department);
            setClientType(client_type);
            setProductType(product_type);
            setVendor(vendor);
            setProduct();
            setProductName(product_name);
            setProductId(product_id);
            setParsedFields(true);
            setSelectedProducts(products)
        }

        setParsedFields(true);

    })

    const handleClickCreate = () => {
        const payload = {
            package_name: packageName,
            zoho_Id: zohoId,
            department_code: departmentCode,
            department,
            client_type: clientType,
            product_type: productType,
            vendor,
            product_name: productName,
            product_id: productId,
            products: selectedProducts ? JSON.stringify(selectedProducts) : ''
        };

        createPackage(payload);
    };

    const handleClickUpdate = () => {
        const payload = {
            package_name: packageName,
            zoho_Id: zohoId,
            department_code: departmentCode,
            department,
            client_type: clientType,
            product_type: productType,
            vendor,
            product_name: productName,
            product_id: productId,
            products: selectedProducts ? JSON.stringify(selectedProducts) : ''
        };

        updatePackage(packageToUpdate.id, payload);
    };

    const handleClose = () => {
        close();
    };

    
    const selectedProduct = (product) => {
        if(!product)
            return

        if(selectedProducts.some(prod => prod.product_id === product.zoho_id))
            return

        const { 
            product_name,
            zoho_id: product_id
        } = product;

        setSelectedProducts([ ...selectedProducts, { product_name, product_id }])
        setProduct('')
    }

    const removeProduct = (product) => {
        setSelectedProducts(selectedProducts.filter(prod => prod.product_id !== product.product_id));
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="package-dialog"
        >
            <DialogTitle id="alert-dialog-slide-title" className="dialog-title">Create New</DialogTitle>
            <DialogContent>
                <div className='package_dialog'>
                    <div className='fields -regular_case'>
                        <TextField
                            className='field -regular_case'
                            label='Package Name'
                            value={packageName}
                            onChange={(event) => setPackageName(event.target.value)}
                        />
                        <TextField
                            className='field -regular_case'
                            label='Zoho ID'
                            value={zohoId}
                            onChange={(event) => setZohoId(event.target.value)}
                        />
                    </div>
                    <div className='fields'>
                    <FormControl variant="outlined" className='field -select'>
                        <InputLabel id="demo-simple-select-outlined-label">Department Code</InputLabel>
                        <Select
                            label="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={departmentCode}
                            displayEmpty
                            className='field -select'
                            label="Department Code"
                            helperText='Department Code'
                            onChange={(event) => setDepartmentCode(event.target.value)}
                            >
                            <MenuItem value='it-ene'>It-ene</MenuItem>
                            <MenuItem value='it-tel'>It-tel</MenuItem>
                            <MenuItem value='it-ins'>It-ins</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className='field -select'>
                        <InputLabel id="demo-simple-select-outlined-label">Department</InputLabel>
                        <Select
                            label="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={department}
                            displayEmpty
                            className='field -select'
                            helperText='Department'
                            label="Department"
                            onChange={(event) => setDepartment(event.target.value)}
                            >
                            <MenuItem value='Energy'>Energy</MenuItem>
                            <MenuItem value='Telecom'>Telecom</MenuItem>
                            <MenuItem value='Extra Sales'>Extra Sales</MenuItem>
                            <MenuItem value='Insurance'>Insurance</MenuItem>
                        </Select>
                    </FormControl>
                    </div>
                    <div className='fields -role'>
                    <FormControl variant="outlined" className='field -select'>
                        <InputLabel id="demo-simple-select-outlined-label">Client Type</InputLabel>
                        <Select
                            label="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={clientType}
                            displayEmpty
                            className='field -select'
                            helperText='Client Type'
                            label="Client Type"
                            onChange={(event) => setClientType(event.target.value)}
                            >
                            <MenuItem value='Domestico'>Domestico</MenuItem>
                            <MenuItem value='Business'>Business</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className='field -select'>
                        <InputLabel id="demo-simple-select-outlined-label">Product Type</InputLabel>
                        <Select
                            label="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={productType}
                            displayEmpty
                            className='field -select'
                            helperText='Product Type'
                            onChange={(event) => setProductType(event.target.value)}
                            >
                            <MenuItem value='Power'>Power</MenuItem>
                            <MenuItem value='Gas'>Gas</MenuItem>
                            <MenuItem value='Other'>Other</MenuItem>
                            <MenuItem value='Internet'>Internet</MenuItem>
                            <MenuItem value='Mobile'>Mobile</MenuItem>
                            <MenuItem value='Insurance'>Insurance</MenuItem>
                        </Select>
                    </FormControl>
                    </div>

                    <div className='fields -role'>
                        <TextField
                            className='field -regular_case'
                            label='Vendor'
                            value={vendor}
                            onChange={(event) => setVendor(event.target.value)}
                        />
                    </div>

                    <div className='fields -role'>
                        <Autocomplete
                            freeSolo
                            className='field -product'
                            onChange={(event, val) => selectedProduct(val)}
                            options={products}
                            value={product}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.product_name)}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Product"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps }}
                            />
                            )}
                        />
                    </div>

                    <div className='fields -list'>
                        <div className='head_selected'>
                            <div className="text">Selected</div>
                            <div className="text">Action</div>
                        </div>
                        <div className="list_packages">
                            {
                                selectedProducts.map(selectedProd => {
                                    return (<div className='package'>
                                        <div className='package_name'>
                                            {selectedProd.product_name}
                                        </div>
                                        <div className='actions'>
                                        <Tooltip title='Remove'>
                                            <IconButton className='action' onClick={(event) => { removeProduct(selectedProd) }}>
                                                <DeleteIcon fontSize='small'/>
                                            </IconButton>
                                        </Tooltip>
                                        </div>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
            { packageToUpdate && packageToUpdate.id 
                ? <Button className='package_create_dialog_action -create' onClick={handleClickUpdate}>
                    Update
                </Button>
                : <Button className='package_create_dialog_action -create' onClick={handleClickCreate}>
                    Create
                </Button>
            }
            <Button className='package_create_dialog_action -close' onClick={handleClose}>
                Close
            </Button>
            
            </DialogActions>
        </Dialog>
        </div>
    );
}