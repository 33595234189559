const style = {
    formBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        position: 'relative',
        top: '-90px',
        backgroundColor: '#1460AA'
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    loginFl: {
        width: '100%',
        marginTop: '20px'
    },

    button: {
        background: '#e9a744',
        borderRadius: 12,
        border: 0,
        color: "white",
        height: 48,
        padding: "20px 70px",
        marginTop: '40px',
        boxShadow: 'none',
    }
}

export default style;