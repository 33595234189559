import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDateTimePicker,
    KeyboardDatePicker,
    DateTimePicker
  } from '@material-ui/pickers';

import { getReport } from '../../../service';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenerateReport(props) {
    const [from, setFrom] = React.useState(new Date());
    const [to, setTo] = React.useState(new Date());

    const {
        closeGenerateReport,
        types = [],
        id
    } = props;

    const create = () => {
        const time = 'startTime=' + moment(from).format('MM/DD/YYYY') + '&endTime=' + moment(to).format('MM/DD/YYYY')

        getReport(time).then(resp => {
            const { 
                ok,
                file
            } = resp.data;

            if(!ok)
                console.log("Can't create a PDF file");

            window.open('/reports-tsbtessgs/' + file, '_blank_' + file);
        })
    }

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='sm'
            onClose={closeGenerateReport}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="reports-dialog"
        >
            <DialogTitle id="max-width-dialog-title">
                <span className='dialo_head_title'>Generate Report</span>
            </DialogTitle>
            <DialogContent>
                <div className='create_pdf_dialog'>
                    <MuiPickersUtilsProvider  utils={MomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            className='field'
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            required
                            id="date-picker-inline"
                            label="Set From"
                            value={from}
                            helperText='Set From'
                            onChange={setFrom}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider  utils={MomentUtils}>
                        <KeyboardDatePicker
                        disableToolbar
                        className='field'
                        variant="inline"
                        format="DD/MM/yyyy"
                        margin="normal"
                        required
                        id="date-picker-inline"
                        label="Set To"
                        value={to}
                        helperText='Set To'
                        onChange={setTo}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                    </MuiPickersUtilsProvider>

                    
                </div>
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -close' onClick={closeGenerateReport}>
                Close
            </Button>
            <Button className='user_create_dialog_action -generate' onClick={create}>
                Generate
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}