export const LOADING_USERS = "LOADING_USERS";
export const ERROR_LOAD_USERS = "ERROR_LOAD_USERS";
export const SAVE_USERS = "SAVE_USERS";
export const SAVE_SEARCH_WORD_USERS = "SAVE_SEARCH_WORD_USERS";
export const CREATE_USER = "CREATE_USER";

export const SET_ORDER_USERS = "SET_ORDER_USERS";
export const SET_ORDER_BY_USERS = "SET_ORDER_BY_USERS";
export const SET_PAGE_USERS = "SET_PAGE_USERS";
export const SET_ROWS_PAGE_USERS = "SET_ROWS_PAGE_USERS";


export const loadingUsers = () => {
    return {
        type: LOADING_USERS,
        loadingRecords: true
    }
}

export const loadingError = () => {
    return {
        type: ERROR_LOAD_USERS,
        loadingRecords: false
    }
}

export const saveUsers = (payload) => {
    const { users, count } = payload;
    
    return {
        type: SAVE_USERS,
        loadingRecords: false,
        users,
        count
    }
}

export const saveSearchWord = (word) => {
    return {
        type: SAVE_SEARCH_WORD_USERS,
        searchWord: word
    }
}

export const setOrder = (order) => {
    return {
        type: SET_ORDER_USERS,
        order
    }
}
export const setOrderBy = (orderBy) => {
    return {
        type: SET_ORDER_BY_USERS,
        orderBy
    }
}
export const setPage = (page) => {
    return {
        type: SET_PAGE_USERS,
        page
    }
}
export const setRowsPerPage = (rows) => {
    return {
        type: SET_ROWS_PAGE_USERS,
        rowsPerPage: rows
    }
}


