export const LOADING_TEMPLATES = "LOADING_TEMPLATES";
export const ERROR_LOAD_TEMPLATES = "ERROR_LOAD_TEMPLATES";
export const SAVE_TEMPLATES = "SAVE_TEMPLATES";
export const SAVE_SEARCH_WORD_TEMPLATES = "SAVE_SEARCH_WORD_TEMPLATES";
export const SET_ORDER_TEMPLATES = "SET_ORDER_TEMPLATES";
export const SET_ORDER_BY_TEMPLATES = "SET_ORDER_BY_TEMPLATES";
export const SET_PAGE_TEMPLATES = "SET_PAGE_TEMPLATES";
export const SET_ROWS_PAGE_TEMPLATES = "SET_ROWS_PAGE_TEMPLATES";


export const loadingRecords = () => {
    return {
        type: LOADING_TEMPLATES,
        loadingRecords: true
    }
}

export const loadingError = () => {
    return {
        type: ERROR_LOAD_TEMPLATES,
        loadingRecords: false
    }
}

export const saveTemplates = (payload) => {
    const { templates, count } = payload;
    
    return {
        type: SAVE_TEMPLATES,
        loadingRecords: false,
        templates,
        count
    }
}

export const saveSearchWrod = (word) => {
    return {
        type: SAVE_SEARCH_WORD_TEMPLATES,
        searchWord: word
    }
}

export const setOrder = (order) => {
    return {
        type: SET_ORDER_TEMPLATES,
        order
    }
}
export const setOrderBy = (orderBy) => {
    return {
        type: SET_ORDER_BY_TEMPLATES,
        orderBy
    }
}
export const setPage = (page) => {
    return {
        type: SET_PAGE_TEMPLATES,
        page
    }
}
export const setRowsPerPage = (rows) => {
    return {
        type: SET_ROWS_PAGE_TEMPLATES,
        rowsPerPage: rows
    }
}