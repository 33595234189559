import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';

import { getLogs } from './../../../service/index';
import './index.scss'


const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserLogsDialog(props) {
   const {
      close,
      user
   } = props;

   const [logs, setLogs] = useState([]);
   const [isLogsLoaded, setIsLogsLoaded] = useState(false);

   useEffect(() => {
      getLogs(user.id).then(result => {
         if (!result.data) {
            setLogs([]);
         } else {
            setLogs(result.data.logs);
         }
         setIsLogsLoaded(true);
      }).catch(err => {
         console.log(err)
      })
   }, [])

   const showLogs = () => {
      if (!logs.length && isLogsLoaded) {
         return (
            <div className="userLogsDialog__text">No logs</div>
         )
      } else {
         return (
            logs.map(log => {
               return (
                  <>
                     <div className="userLogsDialog__item">
                        <div>{moment(log.date).format('DD/MM/YYYY hh:mm a')}</div>
                        <div>{log.ip}</div>
                        <div>{log.action}</div>
                     </div>
                  </>
               )
            })
         )
      }
   }


   return (
      <div>
         <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="addAddresses-dialog"
         >
            <DialogTitle id="alert-dialog-slide-title" className="userLogsDialog__title">See Logs</DialogTitle>

            <DialogContent className="userLogsDialog__content">
               <div className="userLogsDialog">
                  <div className="userLogsDialog__rowHeader">
                     <div className="userLogsDialog__item">Date</div>
                     <div className="userLogsDialog__item">IP</div>
                     <div className="userLogsDialog__item">Action</div>
                  </div>
                  <div className="userLogsDialog__row">
                     {showLogs()}
                  </div>
               </div>
            </DialogContent>
            <DialogActions>
               <Button className='user_create_dialog_action -close' onClick={close}>
                  Close
            </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}