import React, { Component } from 'react';
import { Button, Icon } from '@material-ui/core';
import { Link, Route, HashRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Menu from '../components/menu';
import Switcher from '../components/swither';
import Records from '../pages/records';
import Templates from '../pages/templates';
import UsersPage from '../pages/users';
import Scripts from '../pages/scripts';
import PackagesPage from '../pages/packages';

import GenerateReport from './../components/dialogs/generateReport';
import ManageDomains from './../components/dialogs/manageDomains';
import ManageEmails from './../components/dialogs/manageEmails';
import UploadAddresses from './../components/dialogs/uploadList';
import UploadIbans from './../components/dialogs/uploadIbans';
import ManageQuestions from './../components/dialogs/manageQuestions';
import MailCheckboxes from './../components/dialogs/mailCheckboxes';

import Notification from '../components/notification/notification';


import { 
    searchRecords,
    searchScripts,
    searchTemplates,
    getAllUsers,
    getCustomFields,
    getManageFields,
    getAllPackages
} from '../service';

import { 
    loadingRecords,
    loadingError,
    saveRecords 
} from '../actions/records';

import { 
    saveScripts
} from '../actions/scripts';

import { 
    saveTemplates
} from '../actions/templates';

import { 
    saveCustoms
} from '../actions/customs';

import { 
    saveUsers
} from '../actions/users';

import { 
    saveManagedFields
} from '../actions/managedFields';

import { 
    savePackages
} from '../actions/packages';

// styles
import mainStyle from './mainStyle';

import store from '../store';

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    startLoading: () => {
        return dispatch(loadingRecords());
    },

    saveRecords: (payload) => {
        return dispatch(saveRecords(payload));
    },

    saveScripts: (payload) => {
        return dispatch(saveScripts(payload));
    },

    saveTemplates: (payload) => {
        return dispatch(saveTemplates(payload));
    },

    saveUsers: (payload) => {
        return dispatch(saveUsers(payload));
    },

    saveCustoms: (payload) => {
        return dispatch(saveCustoms(payload));
    },

    saveManagedFields: (fields) => {
        return dispatch(saveManagedFields(fields));
    },

    savePackages: (payload) => {
        return dispatch(savePackages(payload));
    }
})

class Content extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openReportPopupStatus: false,
            openDomainPopupStatus: false,
            openMailCheckboxesStatus: false,
            openUploadPopupStatus: false,
            openIbansPopupStatus:  false,
            openQuestionPopupStatus: false,
            openEmailPopupStatus: false
        }

        this.closeGenerateReport = this.closeGenerateReport.bind(this);
        this.openGenerateReport = this.openGenerateReport.bind(this);

        this.openDomainManager = this.openDomainManager.bind(this);
        this.closeDomainManager = this.closeDomainManager.bind(this);

        this.openEmailsManager = this.openEmailsManager.bind(this);
        this.closeEmailsManager = this.closeEmailsManager.bind(this);

        this.openUploadList = this.openUploadList.bind(this);
        this.closeUploadList = this.closeUploadList.bind(this);

        this.openUploadIbans = this.openUploadIbans.bind(this);
        this.closeUploadIbans = this.closeUploadIbans.bind(this);

        this.openQuestionManager = this.openQuestionManager.bind(this);
        this.closeQuestionManager = this.closeQuestionManager.bind(this);

        this.openMailCheckboxes = this.openMailCheckboxes.bind(this);
        this.closeMailCheckboxes = this.closeMailCheckboxes.bind(this);
    }

    componentWillMount() {
        const role = store.getState().auth.role;

        const { 
            records: { records },
            scripts: { scripts },
            templates: { templates },
            customs: { customs },
            users: { users },
            managedFields: { managedFields },
            packages: { packages }
        } = this.props;

        if(!records[0]) {
            searchRecords().then(resp => {
                const {
                    count,
                    records,
                    ok
                } = resp.data;
    
                if(ok)
                    this.props.saveRecords({ count, records });
    
            })
        }

        if(!packages[0]) {
            getAllPackages().then(resp => {
                const {
                    count,
                    packages,
                    ok
                } = resp.data;

                const parsedPackages = packages.map(pack => {
                    const products = pack.products ? JSON.parse(pack.products) : [];

                    return {
                        ...pack,
                        products
                    }
                })
    
                if(ok)
                    this.props.savePackages({ count, packages: parsedPackages });
    
            })
        }

        if(!scripts[0]) {
            searchScripts().then(resp => {
                const {
                    count,
                    scripts,
                    ok
                } = resp.data;
    
                if(ok)
                    this.props.saveScripts({ count, scripts });
    
            })
        }

        if(!managedFields[0]) {
            getManageFields().then(resp => {
                const {
                    fields,
                    ok
                } = resp.data;

                const parsedfield = fields.map(field => {
                    if(!field)
                        return field;

                    field.properties = JSON.parse(field.properties);

                    return field;
                })

                console.log(parsedfield, 'parsedfield');
    
                if(ok)
                    this.props.saveManagedFields(parsedfield);
    
            })
        }

        if(!templates[0] && role === 'admin') {
            searchTemplates().then(resp => {
                const {
                    count,
                    templates,
                    ok
                } = resp.data;
    
                if(ok)
                    this.props.saveTemplates({ count, templates });
    
            })
        }

        if(!users[0]) {
            getAllUsers().then(resp => {
                const {
                    ok,
                    users,
                    count
                } = resp.data;

                if(ok)
                    this.props.saveUsers({ count, users });
            })
        }

        if(!customs[0] && role === 'admin') {
            getCustomFields().then(resp => {
                const {
                    ok,
                    fields
                } = resp.data;

                if(ok)
                    this.props.saveCustoms(fields);
            })
        }
    }

    closeGenerateReport() {
        this.setState({ openReportPopupStatus: false });
    }

    openGenerateReport() {
        this.setState({ openReportPopupStatus: true });
    }

    openDomainManager() {
        this.setState({ openDomainPopupStatus: true });
    }

    closeDomainManager() {
        this.setState({ openDomainPopupStatus: false });
    }

    openEmailsManager() {
        this.setState({ openEmailPopupStatus: true });
    }

    closeEmailsManager() {
        this.setState({ openEmailPopupStatus: false });
    }

    openMailCheckboxes() {
        this.setState({ openMailCheckboxesStatus: true });
    }

    closeMailCheckboxes() {
        this.setState({ openMailCheckboxesStatus: false });
    }

    openQuestionManager() {
        this.setState({ openQuestionPopupStatus: true });
    }

    closeQuestionManager() {
        this.setState({ openQuestionPopupStatus: false });
    }


    openUploadList() {
        this.setState({ openUploadPopupStatus: true });
    }

    closeUploadList() {
        this.setState({ openUploadPopupStatus: false });
    }

    openUploadIbans() {
        this.setState({ openIbansPopupStatus: true });
    }

    closeUploadIbans() {
        this.setState({ openIbansPopupStatus: false });
    }



    render() {
        const role = store.getState().auth.role;

        console.log(this.state.openReportPopupStatus, 'STATUS')

        return(
            <div style={ mainStyle.container }>
                {/* <Loading text={textLoad} open={openLoad} /> */}
                {/* <HowTo open={openHelpSection} component={comp} close={this.props.closeHelp}/> */}
                {/* <Notification text={text} close={this.props.closeNotification} open={open}/> */}
                {/* <Menu path={ this.props.location.pathname } /> */}
                {this.state.openIbansPopupStatus  && <UploadIbans close={this.closeUploadIbans} />}
                {this.state.openUploadPopupStatus && <UploadAddresses close={this.closeUploadList} />}
                {this.state.openReportPopupStatus && <GenerateReport closeGenerateReport={this.closeGenerateReport} />}
                {this.state.openDomainPopupStatus && <ManageDomains close={this.closeDomainManager} />}
                {this.state.openEmailPopupStatus && <ManageEmails close={this.closeEmailsManager} />}
                {this.state.openMailCheckboxesStatus && <MailCheckboxes close={this.closeMailCheckboxes} />}
                {this.state.openQuestionPopupStatus && <ManageQuestions close={this.closeQuestionManager} />}
                <Menu 
                    role={role} 
                    openUploadIbans={this.openUploadIbans}
                    openUploadList={this.openUploadList} 
                    openGenerateReport={this.openGenerateReport} 
                    openDomainManager={this.openDomainManager}
                    openMailCheckboxes={this.openMailCheckboxes}
                    openQuestionManager={this.openQuestionManager}
                    openEmailsManager={this.openEmailsManager}/>
                <Switcher path={ this.props.location.pathname }/>
                <div style={ mainStyle.content }>
                    <HashRouter>
                        <Switch>
                            <Route path='/records' component={Records}/>
                            { role === 'admin' && <Route path='/scripts' component={Scripts}/>}
                            { role === 'admin' && <Route path='/templates' component={Templates}/>}
                            { role === 'admin' && <Route path='/users' component={UsersPage}/>}
                            { role === 'admin' && <Route path='/packages' component={PackagesPage}/>}
                            
                            <Route path='/' render={() => {
                                return (<Redirect to='/records'/>)
                            }}/>
                        </Switch>
                    </HashRouter>
                </div>

            <Notification/>

            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Content);