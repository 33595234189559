import React, { Component } from 'react';
import { Link, Route, HashRouter, Switch } from 'react-router-dom';

import ScriptsPage from './list';
import CreateScript from './new';

function Scripts() {
    return (
        <HashRouter basename='/scripts'>
            <Switch>
                <Route path='/new' component={CreateScript}/>
                <Route path='/:id' component={CreateScript}/>
                <Route path='/' component={ScriptsPage}/>
            </Switch>
        </HashRouter>
    )
}

export default Scripts