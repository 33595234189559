import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { 
   closeNotification,
} from '../../actions/notification';

const mapStateToProps = state => ({
   ...state.notifications
});

const mapDispatchToProps = dispatch => ({
   closeNotification: () => {
      return dispatch(closeNotification());
   },
})

const useStyles = makeStyles({
   cookieAlert: {
     "& .MuiAlert-icon": {
       color: "#ffffff"
     }
   }
 });

const Notification = (props) => {

   const classes = useStyles();

   const handleClose = () => {
      props.closeNotification();
   }

   return (
      <Snackbar
         open={props.showNotification} 
         autoHideDuration={3000} 
         anchorOrigin= {{vertical: 'top', horizontal: 'right'}}
         onClose={handleClose}
         >
         <Alert style={{ 
               minWidth: '250px',
               background: '#323232',
               color: '#ffffff'
            }} onClose={handleClose} severity={props.notificationType} className={classes.cookieAlert}>
               
            {props.notificationText}
         </Alert>
      </Snackbar> 
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Notification);