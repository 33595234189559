import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';

import './index.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResetGoogleAuthDialog(props) {
    const {
        close,
        reset,
        success
    } = props;

    return (
        <div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="resetAuth-dialog"
        >
            <DialogTitle id="alert-dialog-slide-title">Reset Google Auth</DialogTitle>
            <DialogContent className="resetAuth-dialog__content">
                Are you sure what you want to reset Google Auth for this user?
                {success && <div className="resetAuth-dialog__successText"><DoneRoundedIcon />Google Authentication has been reset</div>}
            </DialogContent>
            <DialogActions>
            <Button className='user_create_dialog_action -generate' onClick={reset}>
                Reset
            </Button>
            <Button className='user_create_dialog_action -close' onClick={close}>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}