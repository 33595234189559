import React, { Component } from 'react';

import './index.scss';

export default function Chip(props) {
    const { count } = props;

    return (
        <div className='chip_button'>{count}</div>
    );
}