import { FIELDS } from './index';

const {
    SAVE_FIELDS
} = FIELDS;

export const saveManagedFields = (managedFields) => {
    return {
        type: SAVE_FIELDS,
        managedFields
    }
}